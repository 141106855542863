import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../locales/languages';

// ----------------------------------------------------------------------



export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGUAGES.find((_lang) => _lang.value === langStorage) || LANGUAGES[1];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGUAGES,
  };
}
