import { sub } from 'date-fns';
import { useRef, useState, useCallback, useMemo } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
// components
import Iconify from 'src/components/iconify';
import { useAuth } from "src/hooks";
import SendIcon from '@mui/icons-material/Send';
import { sendMessage } from 'src/services/documents/documents';
import { useParams } from "react-router-dom";
// types
// ----------------------------------------------------------------------

type Props = {
  recipients: any[];
  onAddRecipients: (recipients: any[]) => void;
  //
  disabled: boolean;
  selectedConversationId: string;
};

export default function ChatMessageInput({
  recipients,
  onAddRecipients,
  //
  disabled,
  selectedConversationId,
}: Props) {

  const { user } = useAuth();
  const { id, listItemId, listItemType } = useParams();

  const fileRef = useRef<HTMLInputElement>(null);

  const [message, setMessage] = useState('');

  // const myContact = useMemo(
  //   () => ({
  //     id: user.id,
  //     role: user.role,
  //     email: user.email,
  //     address: user.address,
  //     name: user.displayName,
  //     lastActivity: new Date(),
  //     avatarUrl: user.photoURL,
  //     phoneNumber: user.phoneNumber,
  //     status: 'online' as 'online' | 'offline' | 'alway' | 'busy',
  //   }),
  //   [user]
  // );

  // const messageData = useMemo(
  //   () => ({
  //     // id: uuidv4(),
  //     attachments: [],
  //     body: message,
  //     contentType: 'text',
  //     createdAt: sub(new Date(), { minutes: 1 }),
  //     senderId: myContact.id,
  //   }),
  //   [message, myContact.id]
  // );

  // const conversationData = useMemo(
  //   () => ({
  //     // id: uuidv4(),
  //     messages: [messageData],
  //     participants: [...recipients, myContact],
  //     type: recipients.length > 1 ? 'GROUP' : 'ONE_TO_ONE',
  //     unreadCount: 0,
  //   }),
  //   [messageData, myContact, recipients]
  // );

  const handleAttach = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }, []);

  const handleChangeMessage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  }, []);

  // const handleSendMessage = useCallback(
  //   async (event: React.KeyboardEvent<HTMLInputElement>) => {
  //     // try {
  //     //   if (event.key === 'Enter') {
  //     //     if (message) {
  //     //       if (selectedConversationId) {
  //     //         await sendMessage(selectedConversationId, messageData);
  //     //       } else {
  //     //         const res = await createConversation(conversationData);
  //     //
  //     //         router.push(`${paths.dashboard.chat}?id=${res.conversation.id}`);
  //     //
  //     //         onAddRecipients([]);
  //     //       }
  //     //     }
  //     //     setMessage('');
  //     //   }
  //     // } catch (error) {
  //     //   console.error(error);
  //     // }
  //   },
  //   [conversationData, message, messageData, onAddRecipients, selectedConversationId]
  // );

  const handleSendMessage = () => {
    if (id && user?.user_uid) {
      sendMessage(id, user?.user_uid, message);
    }
  }

  return (
    <>
      <InputBase
        value={message}
        onKeyUp={() => {}}
        onChange={handleChangeMessage}
        placeholder="Type a message"
        disabled={disabled}
        endAdornment={
          <Stack direction="row" sx={{ flexShrink: 0 }}>
            <IconButton onClick={handleSendMessage}>
              <SendIcon />
            </IconButton>
          </Stack>
        }
        sx={{
          px: 1,
          height: 56,
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      />

      <input type="file" ref={fileRef} style={{ display: 'none' }} />
    </>
  );
}
