/**
 * @description - The all services that are related to the users' data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import { getDoc, getDocs, updateDoc } from 'firebase/firestore';
// Firebase queries
import {
  getUserPrivateReference,
  getUserEmployeeQuery,
  getCompanyProfileQuery,
  getCompaniesProfilesQuery
} from "src/services/user/queries";
import { toAccountProfile, toEmployeeProfile } from './models';
// Types
import { TypeAccountProfile, TypeEmployeeProfile } from "src/@types";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //
/**
 * @description - The method is fetching user based profile
 * @param userUid <string> - The current user uid
 * @param onSuccess <(user: TypeUserProfile | null) => void> - The method  to execute when successfully fetched data
 * @param onFail <(error: Error | string) => void> - The method is executing whenever some issue  happened
 */
export function getUserPrivateProfile(
  userUid: string,
  onSuccess: (user: TypeAccountProfile | null) => void,
  onFail: (error: string) => void
) {
  // Fetching current user info from the server side
  // getDocFromCache(userDbReference) // ToDo add this method instead get doc, because it is cached
  getDoc(getUserPrivateReference(userUid))
    .then((doc) => {
      if (doc.exists()) {
        const userProfileDocument = doc.data();
        if (userProfileDocument) {
          onSuccess(toAccountProfile(userProfileDocument));
        } else {
          onSuccess(null);
        }
      }
    })
    .catch((error) => {
      if (error?.message) {
        onFail(error.message)
      } else {
        onFail('Something went wrong')
      }
    });
}

/**
 * @description - The method is fetching user based profile
 * @param userUid <string> - The current user uid
 * @param companyUid <string> - The current company uid
 * @param onSuccess <(user: TypeEmployeeProfile) => void> - The method  to execute when successfully fetched data
 * @param onFail <(error: Error | string) => void> - The method is executing whenever some issue  happened
 */
export function getUserEmployeeProfile(
  userUid: string,
  companyUid: string,
  onSuccess: (user: TypeEmployeeProfile | null) => void,
  onFail: (error: string) => void
) {
  const employeeProfileQuery = getUserEmployeeQuery(userUid, companyUid);
  // Fetching current user info from the server side
  // getDocFromCache(userDbReference) // ToDo add this method instead get doc, because it is cached
  getDocs(employeeProfileQuery)
    .then((employeeProfileSnapshots) => {
      employeeProfileSnapshots.forEach((doc) => {
        if (doc.exists()) {
          const userProfileDocument = doc.data();
          if (userProfileDocument) {
            onSuccess(toEmployeeProfile(userProfileDocument));
          } else {
            onSuccess(null);
          }
        }
      })
    })
    .catch((error) => {
      if (error?.message) {
        onFail(error.message)
      } else {
        onFail('Something went wrong')
      }
    });
}

/**
 * @description - The  method is  updating user data in the users private reference
 * @param userUid
 * @param userData
 * @param onSuccess
 * @param onFail
 */
export function updateUserByUid(
  userUid: string,
  userData: Record<string, any>,
  onSuccess: () => void,
  onFail: (error:  string) => void
) {
  updateDoc(getUserPrivateReference(userUid), userData)
    .then(onSuccess)
    .catch((error) => {
      if (error?.message) {
        onFail(error.message)
      } else {
        onFail('Something went wrong')
      }
    });
}

/**
 * @description - The method is fetching user based profile
 * @param companyUid <string> - The current company uid
 * @param onSuccess <(user: TypeEmployeeProfile) => void> - The method  to execute when successfully fetched data
 * @param onFail <(error: Error | string) => void> - The method is executing whenever some issue  happened
 */
export function getCompanyProfileByUid(
  companyUid: string,
  onSuccess: (user: TypeAccountProfile | null) => void,
  onFail: (error: string) => void
) {
  const companyProfileQuery = getCompanyProfileQuery(companyUid);
  // Fetching current user info from the server side
  // getDocFromCache(userDbReference) // ToDo add this method instead get doc, because it is cached
  getDocs(companyProfileQuery)
    .then((companyProfileSnapshots) => {
      companyProfileSnapshots.forEach((doc) => {
        if (doc.exists()) {
          const companyProfileDocument = doc.data();
          if (companyProfileDocument) {
            onSuccess(toAccountProfile(companyProfileDocument));
          } else {
            onSuccess(null);
          }
        }
      })
    })
    .catch((error) => {
      if (error?.message) {
        onFail(error.message)
      } else {
        onFail('Something went wrong')
      }
    });
}

/**
 * @description - The method is fetching employee permissions list
 * @param companiesUidList
 * @param onSuccess
 * @param onFail
 */
export function fetchCompaniesProfiles(
  companiesUidList: string[],
  onSuccess: (profiles: TypeAccountProfile[]) => void,
  onFail: (error: Error) => void
) {
  if (companiesUidList?.length > 0) {
    // Getting companies list
    const companiesListQuery = getCompaniesProfilesQuery(companiesUidList);
    getDocs(companiesListQuery)
      .then((querySnapshot) => {
        const listOfCompanies: TypeAccountProfile[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data) {
            const convertedData = toAccountProfile(data);
            if (convertedData) {
              listOfCompanies.push(convertedData);
            }
          }
        });
        onSuccess(listOfCompanies);
      })
      .catch((error) => {onFail(error); console.log(error)});
  }
}
