/**
 * @description - Google pam key
 */
export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

/**
 * @description - Google Analytics Configuration
 */
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

/**
 * @description - The default number of the total data to fetch
 */
export const DEFAULT_NUMBER_OF_DATA = 150;
