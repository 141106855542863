/**
 * @description - The requests page related slices' methods
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// Local types
import { TypeGroup, TypeGroupMember } from 'src/@types';
// API services
import {
  fetchAllGroupsByCompanyUid,
  fetchGroupByUid,
  fetchGroupMembersByUid,
  fetchGroupsTotalNumberByCompanyUid,
  updateGroupMemberByUid,
  createGroupMemberByUid,
} from 'src/services';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The new request form state
 */
type GroupState = {
  isLoading: boolean;
  error: Error | string | null;
  success: string | null;
  totalGroups: number;
  groups: TypeGroup[];
  group: TypeGroup | null;
  members: TypeGroupMember[] | null;
};

/**
 * @description - The initialization of the new request form state
 */
const groupsInitialState: GroupState = {
  isLoading: false,
  error: null,
  success: null,
  totalGroups: 0,
  groups: [],
  group: null,
  members: null,
}

/**
 * @description - The slices for the new request form
 */
const groupsSlice = createSlice({
  name: 'groups',
  initialState: groupsInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
      state.success = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = null;
    },
    // GET TOTAL GROUPS
    getGroupsTotalMembersNumberSuccess(state, action) {
      state.isLoading = false;
      state.totalGroups = action.payload;
    },
    // GET GROUPS
    getGroupsSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
    },
    getGroupByUidSuccess(state, action) {
      state.isLoading = false;
      state.group = action.payload;
    },
    getGroupMembersByUidSuccess(state, action) {
      state.isLoading = false;
      state.members = action.payload;
    },
    deleteGroupMemberByUidSuccess(state) {
      state.isLoading = false;
      state.success = 'Member(s) removed successfully';
    },
    createGroupMemberByUidSuccess(state) {
      state.isLoading = false;
      state.success = 'Member created successfully';
    }
  }
});
// The reducers
export default groupsSlice.reducer;

// State base actions
export const {} = groupsSlice.actions;

// ================================================================================================================== //
// =================================================== ENDPOINTS ==================================================== //
// ================================================================================================================== //

/**
 * @description - The method is fetching the total number of the groups
 * @param companyUid
 */
export function getGroupsTotalNumber(companyUid: string) {
  return () => {
    dispatch(groupsSlice.actions.startLoading());
    try {
      fetchGroupsTotalNumberByCompanyUid(
        companyUid,
        (totalNumber) => dispatch(groupsSlice.actions.getGroupsTotalMembersNumberSuccess(totalNumber)),
        (error) => dispatch(groupsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(groupsSlice.actions.hasError(error))
    }
  }
}

/**
 * @description - The method is fetching new request forms from the server
 */
export function getGroupsByCompanyUid(
  companyUid: string,
  orderByCategory?: string,
  categoryOrderSort?: 'asc' | 'desc',
  limitNumber?: number,
  pageNumber?: number,
) {
  return () => {
    dispatch(groupsSlice.actions.startLoading());
    try {
      fetchAllGroupsByCompanyUid(
        companyUid,
        (dataList) =>
          dispatch(groupsSlice.actions.getGroupsSuccess(dataList)),
        (error) => dispatch(groupsSlice.actions.hasError(error)),
        orderByCategory,
        categoryOrderSort,
        limitNumber,
        pageNumber,
      );
    } catch (error) {
      dispatch(groupsSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The method is fetching new request forms from the server
 */
export function getGroupByUid(departmentUid: string) {
  return () => {
    dispatch(groupsSlice.actions.startLoading());
    try {
      fetchGroupByUid(
        departmentUid,
        (group) => dispatch(groupsSlice.actions.getGroupByUidSuccess(group)),
        (error) => dispatch(groupsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(groupsSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The method is fetching new request forms from the server
 */
export function getGroupMembersByUid(groupUid: string) {
  return () => {
    dispatch(groupsSlice.actions.startLoading());
    try {
      fetchGroupMembersByUid(
        groupUid,
        (groupMembers) => dispatch(groupsSlice.actions.getGroupMembersByUidSuccess(groupMembers)),
        (error) => dispatch(groupsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(groupsSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The method is removing the group member by Uid
 * @param memberUid
 * @param groupUid
 */
export function deleteGroupMemberByUid(groupUid: string, memberUid: string) {
  return () => {
    dispatch(groupsSlice.actions.startLoading());
    try {
      updateGroupMemberByUid(
        groupUid,
        memberUid,
        { active: false, status: 'removed' },
        () => dispatch(groupsSlice.actions.deleteGroupMemberByUidSuccess()),
        (error) => dispatch(groupsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(groupsSlice.actions.hasError(error));
    }
  }
}

export function createGroupMember(
  groupUid: string,
  memberData: Partial<TypeGroupMember>,
) {
  return () => {
    dispatch(groupsSlice.actions.startLoading());
    try {
      createGroupMemberByUid(
        groupUid,
        memberData,
        () => dispatch(groupsSlice.actions.createGroupMemberByUidSuccess()),
        (error) => dispatch(groupsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(groupsSlice.actions.hasError(error));
    }
  }
}
