/**
 * @description - The requests page related slices' methods
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// Local types
import { TypeAccountProfile } from 'src/@types';
// API services
import { fetchCompaniesProfiles } from 'src/services';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The new request form state
 */
type CompaniesProfileState = {
  isCompaniesProfileLoading: boolean;
  companiesProfileFetchingError: Error | string | null;
  companiesProfiles: TypeAccountProfile[];
};

const companiesProfilesInitialState : CompaniesProfileState = {
  isCompaniesProfileLoading: false,
  companiesProfileFetchingError: null,
  companiesProfiles: [],
}

/**
 * @description - The slices for fetching companies profiles
 */
const companiesProfilesSlices = createSlice({
  name: 'companiesProfiles',
  initialState: companiesProfilesInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isCompaniesProfileLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isCompaniesProfileLoading = false;
      state.companiesProfileFetchingError = action.payload;
    },
    // GET COMPANIES PROFILES
    getCompaniesProfilesSuccess(state, action) {
      state.isCompaniesProfileLoading = false;
      state.companiesProfiles = action.payload;
    }
  }
});

// Reducers
export default companiesProfilesSlices.reducer;
// Actions
export const {} = companiesProfilesSlices.actions;

/**
 * @description - The method is triggering fetching user's employeer (companies)  prrofiles
 */
export function getCompaniesProfiles(companiesUidList: string[]) {
  return () => {
    dispatch(companiesProfilesSlices.actions.startLoading());
    try {
      fetchCompaniesProfiles(
        companiesUidList,
        (profilesList) =>
          dispatch(companiesProfilesSlices.actions.getCompaniesProfilesSuccess(profilesList)),
        (error) => dispatch(companiesProfilesSlices.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(companiesProfilesSlices.actions.hasError(error));
    }
  }
}
