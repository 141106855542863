/**
 * @description - The documents page related slices
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// Local types
import {
  TypeFlowModel,
  TypeFlowCategoryModel,
  TypeFlowStatusModel,
  TypeStepModel,
  TypeStepActionModel,
} from 'src/@types';
import { FlowCategoryEnum, StepActionEnum } from 'src/services/flows/models';
// API
import {
  fetchAllFlowsByCompanyUid,
  fetchFlowsTotalNumberByCompanyUid,
  fetchFlowByFlowUid,
  fetchAllStepsByFlowUid,
} from 'src/services/flows/flows';

// ================================================================================================================== //
// ====================================================== STATE ===================================================== //
// ================================================================================================================== //

type FlowsState = {
  isLoading: boolean;
  error: Error | string | null;
  success: string | null;
  info: string | null;
  totalFlows: number;
  flows: TypeFlowModel[];
  flow: TypeFlowModel | null;
  steps: TypeStepModel[];
};
const flowsInitialState: FlowsState = {
  isLoading: false,
  error: null,
  success: null,
  info: null,
  totalFlows: 0,
  flows: [],
  steps: [],
  flow: null,
}

const flowsSlice = createSlice({
  name: 'flows',
  initialState: flowsInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS MESSAGE
    hasError(state, action) {
      state.isLoading = false;
      state.info = null;
      state.success = null;
      state.error = action.payload;
    },
    hasSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.info = null;
      state.success = action.payload;
    },
    hasInfo(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = null;
      state.info = action.payload;
    },
    resetMessages(state) {
      state.error = null;
      state.success = null;
      state.info = null;
    },
    // GET FLOWS
    getTotalFlowsSuccess(state, action) {
      state.isLoading = false;
      state.totalFlows = action.payload;
    },
    getFlowsSuccess(state, action) {
      state.isLoading = false;
      state.flows = action.payload;
    },
    // GET FLOW
    getFlowSuccess(state, action) {
      state.isLoading = false;
      state.flow = action.payload;
    },
    // GET STEPS
    getStepsSuccess(state, action) {
      state.isLoading = false;
      state.steps = action.payload;
    },
  }
});

export default flowsSlice.reducer;
export const {} = flowsSlice.actions;

// ================================================================================================================== //
// ==================================================== LOGIC | COMMON ============================================== //
// ================================================================================================================== //

/**
 * @description - The private method for the common fetch of the flows number
 * @param companyUid - The company uid
 * @param active - the status of the document
 */
export function getFlowsTotalNumber(companyUid: string, active?: boolean) {
  return () => {
    dispatch(flowsSlice.actions.startLoading());
    try {
      fetchFlowsTotalNumberByCompanyUid(
        companyUid,
        (total) => dispatch(flowsSlice.actions.getTotalFlowsSuccess(total)),
        (error) => dispatch(flowsSlice.actions.hasError(error)),
        active,
      );
    } catch (error) {
      dispatch(flowsSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The common method to fetch flows by company uid
 * @param userUid
 * @param companyUid
 * @param flowCategory
 * @param active
 * @param flowStatus
 * @param limitNumber
 * @param pageNumber
 * @param orderField
 * @param orderDirection
 */
export function getFlowsListByCompanyUid(
  userUid: string,
  companyUid: string,
  flowCategory?: TypeFlowCategoryModel,
  active?: boolean,
  flowStatus?: TypeFlowStatusModel,
  limitNumber?: number,
  pageNumber?: number, // ToDo add correct pagination
  orderField?: string,
  orderDirection?: "asc" | "desc",
) {
  return () => {
    dispatch(flowsSlice.actions.startLoading());
    try {
      fetchAllFlowsByCompanyUid(
        userUid,
        companyUid,
        (flowsList) => dispatch(flowsSlice.actions.getFlowsSuccess(flowsList)),
        (error) => dispatch(flowsSlice.actions.hasError(error)),
        flowCategory,
        flowStatus,
        limitNumber,
        undefined, // ToDo add correct pagination
        orderField,
        orderDirection,
        active,
      );
    } catch (error) {
      dispatch(flowsSlice.actions.hasError(error));
    }
  }
}

export function getFlowByFlowUid(flowUid: string) {
  return () => {
    dispatch(flowsSlice.actions.startLoading());
    try {
      fetchFlowByFlowUid(
        flowUid,
        (flow) => dispatch(flowsSlice.actions.getFlowSuccess(flow)),
        (error) => dispatch(flowsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(flowsSlice.actions.hasError(error));
    }
  }
}

export function getStepsByFlowUid(flowUid: string) {
  return () => {
    dispatch(flowsSlice.actions.startLoading());
    try {
      fetchAllStepsByFlowUid(
        flowUid,
        (stepsList) => dispatch(flowsSlice.actions.getStepsSuccess(stepsList)),
        (error) => dispatch(flowsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(flowsSlice.actions.hasError(error));
    }
  }
}
