/**
 * @description - The converter file that transforms server side data into internal object
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Document data from the firestore
import { DocumentData, Timestamp } from 'firebase/firestore';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The group status
 */
export type GroupStatus = 'active' | 'suspended' | 'removed';

/**
 * @description - The group object's type
 */
export type Group = {
  uid: string,
  manager_uid: string,
  company_uid: string,
  display_name: string,
  status: GroupStatus,
  members_count: number,
  category: string,
  created: Timestamp,
  updated: Timestamp,
  email: string
  active?: boolean,
};

/**
 * @description - The group object's type
 */
export type GroupMember = {
  uid: string,
  user_uid: string,
  company_uid: string,
  email: string,
  display_name: string,
  created: Timestamp,
  updated: Timestamp,
  status: GroupStatus,
  active?: boolean,
};

/**
 * @description - validate the employee profile object
 * ToDo add the logic
 * @param profile
 */
function validateGroup(profile: Group | GroupMember): boolean {
  return true;
}

/**
 * @description - The method converting the firestore data into the group member object
 * @param documentData
 */
export function toGroup(documentData: DocumentData): Group | null {
  const groupData: Group = {
    uid: documentData.uid,
    manager_uid: documentData.manager_uid,
    display_name: documentData.display_name,
    status: documentData.status,
    members_count: documentData.members_count || 0,
    category: documentData.category,
    company_uid: documentData.company_uid,
    created: documentData.created,
    updated: documentData.updated,
    email: documentData.manager_email ?? '',
  };
  if (validateGroup(groupData)) {
    return groupData;
  }
  return null;
}

/**
 * @description - The method is converting the firestore data into the group member object
 * @param documentData
 */
export function toGroupMember(documentData: DocumentData): GroupMember | null {
  const groupData: GroupMember = {
    uid: documentData.uid,
    user_uid: documentData.user_uid,
    company_uid: documentData.company_uid,
    email: documentData.email,
    display_name: documentData.display_name,
    status: documentData.status,
    active: documentData.active,
    created: documentData.created,
    updated: documentData.updated,
  };
  if (validateGroup(groupData)) {
    return groupData;
  }
  return null;
}
