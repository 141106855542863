import React from "react";
import { Container } from "@mui/material";
import { sendEmailNoReload } from "../../services/documents/documents";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // logErrorToMyService(error, info.componentStack);
    // sendEmailNoReload(`errorAccure for`, `${error?.message ?? 'no message'} ${error} ${Object.values(info)} ${Object.keys(info)}`, 'vladimirdanielyan@gmail.com')
    console.log(error);
  }

  onErrorSuccessCatch = () => {
    this.setState({
      hasError: true
    })
  }

  onErrorFailedCatch = (errorMessage) => {
    // ToDo update method to post to firebase database the error
    this.onErrorSuccessCatch()
  }

  // @ts-ignore
  render() {
    if (this.state.hasError) {
      return <div className="wrapper wrapper-full-page">
        <div className="full-page section-image">
          <Container>
            <h2 className={'text-center text-white'}>Error</h2>
            <button onClick={() => {}}>Report The Issue</button>
          </Container>
        </div>
      </div>
    }

    return this.props.children;
  }
}