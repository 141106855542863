/**
 * @description - The routing path variables
 */

/**
 * @description - The method is concat paths and return final path as string.
 * @example - Above example returns string of final path '/app/dashboard'
 * path('/app', '/dashboard')
 * @param root - Root link
 * @param subLink - SubLink
 * @return string - Final path after concatenation
 */
function path(root: string, subLink: string) {
  // Checking if the root path is just a '/', in that case the final path should
  // be only the sub-link
  return root !== '/' ? `${root}/${subLink}` : subLink;
}

/**
 * @description - The method is building the final link based on the provided list of the sub-links
 * @param root
 * @param subLinks
 */
function buildPaths(root: string, subLinks: string[]) {
  return `${root !== '/' ? root : ''}/${subLinks.map((subLink: string) => subLink).join('/')}`;
}

// ================================================================================================================== //
// ====================================================== COMMON ==================================================== //
// ================================================================================================================== //

// Landing page
export const PATH_FULL_LANDING = 'https://bisflow.io';
// Admin application path
export const PATH_FULL_ADMIN = 'https://admin.bisflow.io';
// Requests
export const PATH_FULL_REQUESTS = 'https://request.bisflow.io';
// Document management application
export const PATH_FULL_DOCS = 'https://docs.bisflow.io';
// Contracts management application
export const PATH_FULL_CONTRACT = 'https://contract.bisflow.io';
// Procurement application to manage buying staff
export const PATH_FULL_PROCUREMENT = 'https://procurement.bisflow.io';
// Request management application full path
export const PATH_FULL_REQUEST_MANAGEMENT = 'https://requests.bisflow.io';
// HR management application to manage HR workflow
export const PATH_FULL_HR = 'https://hr.bisflow.io';
// Payva - Expense management application
export const PATH_FULL_PAYVA = 'https://payva.bisflow.io';
// Home - The path to the home
export const PATH_ROOTS_HOME = '/';
// ================================================================================================================== //
// ======================================================= COMMON =================================================== //
// ================================================================================================================== //

/**
 * SUB-LINK COMMON SUB PATHS
 */
const PATH_DASHBOARD = 'dashboard';
const PATH_SETTINGS = 'settings';
const PATH_PROCESSES = 'processes';
const PATH_TEAM = 'team';
const PATH_PERSONNEL = 'personnel';
const PATH_DEPARTMENTS = 'departments';
const PATH_DEPARTMENT = 'department';
const PATH_GROUPS = 'groups';
const PATH_BUDGETS = 'budgets';
const PATH_BUDGET = 'budget';
const PATH_PAYMENTS = 'payments';
const PATH_INVOICES = 'invoices';
const PATH_FLOWS = 'flows';
const PATH_FLOW = 'flow';
const PATH_FORMS = 'forms';
const PATH_FORM = 'form';
const PATH_STOCKS = 'stocks';
const PATH_GOODS = 'goods';
const PATH_MANAGE = 'manage';
const PATH_DOCUMENTS = 'documents';
const PATH_DOCUMENT = 'document';
const PATH_CONTRACTS = 'contracts';
const PATH_CONTRACT = 'contract';
const PATH_ACTS = 'acts';
const PATH_ACT = 'acts';
const PATH_SUPPLEMENT = 'supplement';
const PATH_ATTACHMENT = 'attachment';
const PATH_TERMINATION = 'termination';
const PATH_AUTHORIZATIONS = 'authorizations';
const PATH_ARCHIVE = 'archive';
const PATH_ACTIVE = 'active';
const PATH_TEMPLATES = 'templates';
const PATH_PROCUREMENT = 'procurement';
const PATH_FINANCIAL = 'financial';
const PATH_INVENTORY = 'inventory';
const PATH_VENDORS = 'vendors';
const PATH_REQUESTS = 'requests';
const PATH_REPORTS = 'reports';
const PATH_REPORT = 'report';
const PATH_ANALYTICS = 'analytics';
const PATH_MY = 'my';
const PATH_EMAIL = 'email';
const PATH_LANDING = 'home';
const PATH_COMING_SOON = 'coming-soon';
export const PATH_EDIT = 'edit';
export const PATH_VIEW = 'view';
export const PATH_CREATE = 'create';
export const PATH_LIST = 'list';
export const PATH_ITEM_ID = ':id';
export const PATH_DOCUMENT_ID = ':documentId';
export const PATH_LIST_ITEM_ID = ':listItemId';
export const PATH_LIST_ITEM_TYPE = ':listItemType';

// ================================================================================================================== //
// ======================================================== AUTH ==================================================== //
// ================================================================================================================== //

/**
 * SUB-LINK AUTHENTICATION SUB PATHS
 */
const PATH_AUTH = 'auth';
const PATH_ROOTS_AUTH = `${PATH_ROOTS_HOME}${PATH_AUTH}`;
const PATH_LOGIN = 'login-password';
const PATH_LOGIN_EMAIL = 'login-email';
const PATH_LOGIN_UNPROTECTED = 'login-password-unprotected';
const PATH_REGISTER = 'register';
const PATH_REGISTER_UNPROTECTED = 'register-unprotected';
const PATH_RESET_PASSWORD = 'reset-password';
const PATH_VERIFY_PASSWORD = 'verify-password';

/**
 * @description - Contains authentication related all paths
 */
export const PATH_APP_AUTH = {
  rootFull: PATH_ROOTS_AUTH,
  root: PATH_AUTH,
  loginFull: path(PATH_ROOTS_AUTH, PATH_LOGIN),
  login: PATH_LOGIN,
  loginEmailFull: path(PATH_ROOTS_AUTH, PATH_LOGIN_EMAIL),
  loginEmail: PATH_LOGIN_EMAIL,
  loginUnprotectedFull: path(PATH_ROOTS_AUTH, PATH_LOGIN_UNPROTECTED),
  loginUnprotected: PATH_LOGIN_UNPROTECTED,
  registerFull: path(PATH_ROOTS_AUTH, PATH_REGISTER),
  register: PATH_REGISTER,
  registerUnprotectedFull: path(PATH_ROOTS_AUTH, PATH_REGISTER_UNPROTECTED),
  registerUnprotected: PATH_REGISTER_UNPROTECTED,
  resetPasswordFull: path(PATH_ROOTS_AUTH, PATH_RESET_PASSWORD),
  resetPassword: PATH_RESET_PASSWORD,
  verifyFull: path(PATH_ROOTS_AUTH, PATH_VERIFY_PASSWORD),
  verify: PATH_VERIFY_PASSWORD
};

// ================================================================================================================== //
// ===================================================== PLATFORM =================================================== //
// ================================================================================================================== //

/**
 * SUB_LINKS - ADMIN SUB PATHS
 */
const PATH_APP = 'app';
const PATH_ROOTS_APP = `${PATH_ROOTS_HOME}${PATH_APP}`;

/**
 * @description - Contains platform based paths
 */
export const PATH_APP_PLATFORM = {
  rootFull: PATH_ROOTS_APP,
  root: PATH_APP,
}

// ================================================================================================================== //
// ===================================================== ACCOUNT ==================================================== //
// ================================================================================================================== //

/**
 * SUB_LINKS - ADMIN SUB PATHS
 */
const PATH_ACCOUNT = 'account';
const PATH_ROOTS_ACCOUNT = `${PATH_ROOTS_HOME}${PATH_ACCOUNT}`;
const PATH_PROFILE = 'profile';

/**
 * @description - Contains platform based paths
 */
export const PATH_APP_ACCOUNT = {
  rootFull: PATH_ROOTS_ACCOUNT,
  root: PATH_ACCOUNT,
  profileFull: path(PATH_ROOTS_ACCOUNT, PATH_PROFILE),
  profile: PATH_PROFILE,
  settingsFull: path(PATH_ROOTS_ACCOUNT, PATH_SETTINGS),
  settings: PATH_SETTINGS
}

// ================================================================================================================== //
// ======================================================= HELP ===================================================== //
// ================================================================================================================== //

/**
 * SUB_LINKS - ADMIN SUB PATHS
 */
const PATH_HELP = 'help';
const PATH_ROOTS_HELP = `${PATH_ROOTS_HOME}${PATH_HELP}`;
const PATH_INSTRUCTION = 'instructions';

/**
 * @description - Contains platform based paths
 */
export const PATH_APP_HELP = {
  rootFull: PATH_ROOTS_HELP,
  root: PATH_HELP,
  instructionsFull: path(PATH_ROOTS_HELP, PATH_INSTRUCTION),
  profile: PATH_INSTRUCTION
}

// ================================================================================================================== //
// ======================================================== MAIN ==================================================== //
// ================================================================================================================== //
/**
 * SUB_LINKS - ADMIN SUB PATHS
 */
const PATH_APPS = 'apps';
const PATH_ROOTS_APPS = `${PATH_ROOTS_HOME}${PATH_APPS}`;

/**
 * @description - Contains main/home related all paths
 */
export const PATH_APP_MAIN = {
  root: PATH_ROOTS_HOME,
  rootFull: PATH_ROOTS_HOME,
  apps: PATH_APPS,
  appsFull: PATH_ROOTS_APPS,
  landing: PATH_LANDING,
  landingFull: PATH_LANDING,
  comingSoon: PATH_COMING_SOON,
  comingSoonFull: PATH_COMING_SOON,
}
// ================================================================================================================== //
// ===================================================== REQUESTS =================================================== //
// ================================================================================================================== //
/**
 * SUB_LINKS - REQUESTS SUB PATHS
 */

const PATH_MANAGEMENT = 'management';

/**
 * @description - Contains main/home related all paths
 */
export const PATH_APP_REQUESTS = {
  root: PATH_APP,
  rootFull: PATH_ROOTS_APP,
  dashboardFull: path(PATH_ROOTS_APP, PATH_DASHBOARD),
  dashboard: PATH_DASHBOARD,
  requests: {
    root: PATH_REQUESTS,
    rootFull: path(PATH_ROOTS_APP, PATH_REQUESTS),
    manage: path(PATH_REQUESTS, PATH_MANAGE),
    calendar: path(PATH_REQUESTS, 'calendar'),
    manageFull: path(PATH_ROOTS_APP, path(PATH_REQUESTS, PATH_MANAGE)),
    self: path(PATH_REQUESTS, PATH_MY),
    selfFull: path(PATH_ROOTS_APP, path(PATH_REQUESTS, PATH_MY)),
    create: path(PATH_REQUESTS, PATH_CREATE),
    createFull: path(PATH_ROOTS_APP, path(PATH_REQUESTS, PATH_CREATE)),
  },
  reports: {
    root: PATH_REPORTS,
    rootFull: path(PATH_ROOTS_APP, PATH_REPORTS),
    report: path(PATH_REPORTS, PATH_REPORT),
    reportFull: path(PATH_ROOTS_APP, path(PATH_REPORTS, PATH_REPORT)),
    analytics: path(PATH_REPORTS, PATH_ANALYTICS),
    analyticsFull: path(PATH_ROOTS_APP, path(PATH_REPORTS, PATH_ANALYTICS)),
  },
}
// ================================================================================================================== //
// ======================================================= ADMIN ==================================================== //
// ================================================================================================================== //

/**
 * SUB_LINKS - ADMIN SUB PATHS
 */


/**
 * @description - Contains administrative related all paths
 */
export const PATH_APP_ADMIN = {
  rootFull: PATH_ROOTS_APP,
  root: PATH_APP,
  dashboardFull: path(PATH_ROOTS_APP, PATH_DASHBOARD),
  dashboard: PATH_DASHBOARD,
  processes: {
    root: PATH_PROCESSES,
    rootFull: path(PATH_ROOTS_APP, PATH_PROCESSES),
    flows: {
      root: path(PATH_PROCESSES, PATH_FLOWS),
      full: buildPaths(PATH_ROOTS_APP, [PATH_PROCESSES, PATH_FLOWS]),
      create: buildPaths(PATH_PROCESSES, [PATH_FLOWS, PATH_CREATE]),
      createFull: buildPaths(PATH_ROOTS_APP, [PATH_PROCESSES, PATH_FLOWS, PATH_CREATE]),
      view: buildPaths(PATH_PROCESSES, [PATH_FLOWS, PATH_ITEM_ID, PATH_VIEW]),
      viewFull: buildPaths(PATH_ROOTS_APP, [PATH_PROCESSES, PATH_FLOWS, PATH_ITEM_ID, PATH_VIEW]),
      viewFullGenerate: (uid: string) => buildPaths(PATH_ROOTS_APP, [PATH_PROCESSES, PATH_FLOWS, uid, PATH_VIEW]),
      viewRedirect: buildPaths(PATH_PROCESSES, [PATH_FLOWS, PATH_ITEM_ID]),
      edit: buildPaths(PATH_PROCESSES, [PATH_FLOWS, PATH_ITEM_ID, PATH_EDIT]),
      editFull: buildPaths(PATH_ROOTS_APP, [PATH_PROCESSES, PATH_FLOWS, PATH_ITEM_ID, PATH_EDIT]),
      editFullGenerate: (uid: string) => buildPaths(PATH_ROOTS_APP, [PATH_PROCESSES, PATH_FLOWS, uid, PATH_EDIT]),
    },
    formsFull: path(PATH_ROOTS_APP, path(PATH_PROCESSES, PATH_FORMS)),
    forms: path(PATH_PROCESSES, PATH_FORMS),
    formCreate: path(path(PATH_PROCESSES, PATH_FORM), PATH_CREATE),
    formCreateFull: path(PATH_ROOTS_APP, path(path(PATH_PROCESSES, PATH_FORM), PATH_CREATE)),
    formEdit: path(path(path(PATH_PROCESSES, PATH_FORM), PATH_ITEM_ID), PATH_EDIT),
    formEditFull: path(PATH_ROOTS_APP, path(path(path(PATH_PROCESSES, PATH_FORM), PATH_ITEM_ID), PATH_EDIT)),
    formEditFullGenerate:
      (uid: string) => path(PATH_ROOTS_APP, path(path(path(PATH_PROCESSES, PATH_FORM), uid), PATH_EDIT)),
    formView: path(path(path(PATH_PROCESSES, PATH_FORM), PATH_ITEM_ID), PATH_VIEW),
    formViewFull: path(PATH_ROOTS_APP, path(path(path(PATH_PROCESSES, PATH_FORM), PATH_ITEM_ID), PATH_VIEW)),
    formViewFullGenerate:
      (uid: string) => path(PATH_ROOTS_APP, path(path(path(PATH_PROCESSES, PATH_FORM), uid), PATH_VIEW)),
    formViewRedirect: path(path(PATH_PROCESSES, PATH_FORM), PATH_ITEM_ID),
  },
  personnel: {
    rootFull: path(PATH_ROOTS_APP, PATH_PERSONNEL),
    root: PATH_PERSONNEL,
    // Team
    teamFull: path(PATH_ROOTS_APP, path(PATH_PERSONNEL, PATH_TEAM)),
    team: path(PATH_PERSONNEL, PATH_TEAM),
    personnelCreateFull: path(PATH_ROOTS_APP, path(path(PATH_PERSONNEL, PATH_TEAM), PATH_CREATE)),
    personnelCreate: path(path(PATH_PERSONNEL, PATH_TEAM), PATH_CREATE),
    personnelEditFull: path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_TEAM), PATH_ITEM_ID), PATH_EDIT)),
    personnelEditFullGenerate:
      (uid: string) => path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_TEAM), uid), PATH_EDIT)),
    personnelEdit: path(path(path(PATH_PERSONNEL, PATH_TEAM), PATH_ITEM_ID), PATH_EDIT),
    personViewFull: path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_TEAM), PATH_ITEM_ID), PATH_VIEW)),
    personViewFullGenerate:
      (uid: string) => path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_TEAM), uid), PATH_VIEW)),
    personView: path(path(path(PATH_PERSONNEL, PATH_TEAM), PATH_ITEM_ID), PATH_VIEW),
    personViewRedirect: path(path(PATH_PERSONNEL, PATH_TEAM), PATH_ITEM_ID),
    // Department
    departmentsFull: path(PATH_ROOTS_APP, path(PATH_PERSONNEL, PATH_DEPARTMENTS)),
    departments: path(PATH_PERSONNEL, PATH_DEPARTMENTS),
    departmentCreateFull: path(PATH_ROOTS_APP, path(path(PATH_PERSONNEL, PATH_DEPARTMENT), PATH_CREATE)),
    departmentCreate: path(path(PATH_PERSONNEL, PATH_DEPARTMENT), PATH_CREATE),
    departmentEditFull: path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_DEPARTMENT), PATH_ITEM_ID), PATH_EDIT)),
    departmentEditFullGenerate:
      (uid: string) => path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_DEPARTMENT), uid), PATH_EDIT)),
    departmentEdit: path(path(path(PATH_PERSONNEL, PATH_DEPARTMENT), PATH_ITEM_ID), PATH_EDIT),
    departmentViewFull: path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_DEPARTMENT), PATH_ITEM_ID), PATH_VIEW)),
    departmentViewFullGenerate:
      (uid: string) => path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_DEPARTMENT), uid), PATH_VIEW)),
    departmentView: path(path(path(PATH_PERSONNEL, PATH_DEPARTMENT), PATH_ITEM_ID), PATH_VIEW),
    departmentViewRedirect: path(path(PATH_PERSONNEL, PATH_DEPARTMENT), PATH_ITEM_ID),
    // Group
    groupsFull: path(PATH_ROOTS_APP, path(PATH_PERSONNEL, PATH_GROUPS)),
    groups: path(PATH_PERSONNEL, PATH_GROUPS),
    groupCreateFull: path(PATH_ROOTS_APP, path(path(PATH_PERSONNEL, PATH_GROUPS), PATH_CREATE)),
    groupCreate: path(path(PATH_PERSONNEL, PATH_GROUPS), PATH_CREATE),
    groupEditFull: path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_GROUPS), PATH_ITEM_ID), PATH_EDIT)),
    groupEditFullGenerate:
      (uid: string) => path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_GROUPS), uid), PATH_EDIT)),
    groupEdit: path(path(path(PATH_PERSONNEL, PATH_GROUPS), PATH_ITEM_ID), PATH_EDIT),
    groupViewFull: path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_GROUPS), PATH_ITEM_ID), PATH_VIEW)),
    groupViewFullGenerate:
      (uid: string) => path(PATH_ROOTS_APP, path(path(path(PATH_PERSONNEL, PATH_GROUPS), uid), PATH_VIEW)),
    groupView: path(path(path(PATH_PERSONNEL, PATH_GROUPS), PATH_ITEM_ID), PATH_VIEW),
    groupViewRedirect: path(path(PATH_PERSONNEL, PATH_GROUPS), PATH_ITEM_ID),
  },
  finance: {
    rootFull: path(PATH_ROOTS_APP, PATH_FINANCIAL),
    root: PATH_FINANCIAL,
    // Budget
    budgetsFull: path(PATH_ROOTS_APP, path(PATH_FINANCIAL, PATH_BUDGETS)),
    budgets: path(PATH_FINANCIAL, PATH_BUDGETS),
    budgetCreateFull: path(PATH_ROOTS_APP, path(path(PATH_FINANCIAL, PATH_BUDGET), PATH_CREATE)),
    budgetCreate: path(path(PATH_FINANCIAL, PATH_BUDGET), PATH_CREATE),
    budgetEditFull: path(PATH_ROOTS_APP, path(path(path(PATH_FINANCIAL, PATH_BUDGET), PATH_ITEM_ID), PATH_EDIT)),
    budgetEditFullGenerate:
      (uid: string) => path(PATH_ROOTS_APP, path(path(path(PATH_FINANCIAL, PATH_BUDGET), uid), PATH_EDIT)),
    budgetEdit: path(path(path(PATH_FINANCIAL, PATH_BUDGET), PATH_ITEM_ID), PATH_EDIT),
    budgetViewFull: path(PATH_ROOTS_APP, path(path(path(PATH_FINANCIAL, PATH_BUDGET), PATH_ITEM_ID), PATH_VIEW)),
    budgetViewFullGenerate:
      (uid: string) => path(PATH_ROOTS_APP, path(path(path(PATH_FINANCIAL, PATH_BUDGET), uid), PATH_VIEW)),
    budgetView: path(path(path(PATH_FINANCIAL, PATH_BUDGET), PATH_ITEM_ID), PATH_VIEW),
    budgetViewRedirect: path(path(PATH_FINANCIAL, PATH_BUDGET), PATH_ITEM_ID),
    // Payments
    paymentsFull: path(PATH_ROOTS_APP, path(PATH_FINANCIAL, PATH_PAYMENTS)),
    payments: path(PATH_FINANCIAL, PATH_PAYMENTS),
    invoicesFull: path(PATH_ROOTS_APP, path(PATH_FINANCIAL, PATH_INVOICES)),
    invoices: path(PATH_FINANCIAL, PATH_INVOICES),
  },
  inventory: {
    rootFull: path(PATH_ROOTS_APP, PATH_INVENTORY),
    root: PATH_INVENTORY,
    stocksFull: path(PATH_ROOTS_APP, path(PATH_INVENTORY, PATH_STOCKS)),
    stocks: path(PATH_INVENTORY, PATH_STOCKS),
    goodsFull: path(PATH_ROOTS_APP, path(PATH_INVENTORY, PATH_GOODS)),
    goods: path(PATH_INVENTORY, PATH_GOODS),
  },
  vendors: {
    rootFull: path(PATH_ROOTS_APP, PATH_VENDORS),
    root: PATH_VENDORS,
    manageFull: path(PATH_ROOTS_APP, path(PATH_VENDORS, PATH_MANAGE)),
    manage: path(PATH_VENDORS, PATH_MANAGE),
  },
  requests: {
    rootFull: path(PATH_ROOTS_APP, PATH_REQUESTS),
    root: PATH_REQUESTS,
    goodsFull: path(PATH_ROOTS_APP, path(PATH_REQUESTS, PATH_GOODS)),
    goods: path(PATH_REQUESTS, PATH_GOODS),
  },
  documents: {
    rootFull: path(PATH_ROOTS_APP, PATH_DOCUMENTS),
    root: PATH_DOCUMENTS,
    archiveFull: path(PATH_ROOTS_APP, path(PATH_DOCUMENTS, PATH_ARCHIVE)),
    archive: path(PATH_DOCUMENTS, PATH_ARCHIVE),
    authorizationsFull: path(PATH_ROOTS_APP, path(PATH_DOCUMENTS, PATH_AUTHORIZATIONS)),
    authorizations: path(PATH_DOCUMENTS, PATH_AUTHORIZATIONS),
    activeFull: path(PATH_ROOTS_APP, path(PATH_DOCUMENTS, PATH_ACTIVE)),
    active: path(PATH_DOCUMENTS, PATH_ACTIVE),
  },
  reports: {
    rootFull: path(PATH_ROOTS_APP, PATH_REPORTS),
    root: PATH_REPORTS,
    procurementFull: path(PATH_ROOTS_APP, path(PATH_REPORTS, PATH_PROCUREMENT)),
    procurement: path(PATH_REPORTS, PATH_PROCUREMENT),
    documentsFull: path(PATH_ROOTS_APP, path(PATH_REPORTS, PATH_DOCUMENTS)),
    documents: path(PATH_REPORTS, PATH_DOCUMENTS),
    financialFull: path(PATH_ROOTS_APP, path(PATH_REPORTS, PATH_FINANCIAL)),
    financial: path(PATH_REPORTS, PATH_FINANCIAL),
  },
  analytics: {
    rootFull: path(PATH_ROOTS_APP, PATH_ANALYTICS),
    root: PATH_ANALYTICS,
    procurementFull: path(PATH_ROOTS_APP, path(PATH_ANALYTICS, PATH_PROCUREMENT)),
    procurement: path(PATH_ANALYTICS, PATH_PROCUREMENT),
    documentsFull: path(PATH_ROOTS_APP, path(PATH_ANALYTICS, PATH_DOCUMENTS)),
    documents: path(PATH_ANALYTICS, PATH_DOCUMENTS),
    financialFull: path(PATH_ROOTS_APP, path(PATH_ANALYTICS, PATH_FINANCIAL)),
    financial: path(PATH_ANALYTICS, PATH_FINANCIAL),
  },
}

// ================================================================================================================== //
// ======================================================== DOCS ==================================================== //
// ================================================================================================================== //

/**
 * @description - Contains documentation related all paths
 */
export const PATH_APP_DOCS = {
  rootFull: PATH_ROOTS_APP,
  root: PATH_APP,
  dashboard: {
    root: PATH_DASHBOARD,
    full: path(PATH_ROOTS_APP, PATH_DASHBOARD),
  },
  active: {
    root: PATH_ACTIVE,
    rootFull: path(PATH_ROOTS_APP, PATH_ACTIVE),
    templates: {
      root: path(PATH_ACTIVE, PATH_TEMPLATES),
      full: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_TEMPLATES]),
      create: buildPaths(PATH_ACTIVE, [PATH_TEMPLATES, PATH_CREATE]),
      createFull: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_TEMPLATES, PATH_CREATE]),
      view: buildPaths(PATH_ACTIVE, [PATH_TEMPLATES, PATH_ITEM_ID, PATH_VIEW]),
      viewFull: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_TEMPLATES, PATH_ITEM_ID, PATH_VIEW]),
      viewFullGenerate:
        (uid: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_TEMPLATES, uid, PATH_VIEW]),
      edit: buildPaths(PATH_ACTIVE, [PATH_TEMPLATES, PATH_ITEM_ID, PATH_EDIT]),
      editFull: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_TEMPLATES, PATH_ITEM_ID, PATH_EDIT]),
      editFullGenerate:
        (uid: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_TEMPLATES, uid, PATH_EDIT]),
      doc: {
        view: buildPaths(PATH_ACTIVE, [PATH_TEMPLATES, PATH_ITEM_ID, PATH_DOCUMENT, PATH_LIST_ITEM_ID, PATH_VIEW]),
        viewFullGenerate:
          (uid: string, documentId?: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_TEMPLATES, uid, PATH_DOCUMENT, documentId ?? '', PATH_VIEW]),
        edit: buildPaths(PATH_ACTIVE, [PATH_TEMPLATES, PATH_ITEM_ID, PATH_DOCUMENT, PATH_LIST_ITEM_ID, PATH_EDIT]),
        editFullGenerate:
          (uid: string, documentId?: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_TEMPLATES, uid, PATH_DOCUMENT, documentId ?? '', PATH_EDIT]),
      },
    },
    contracts: {
      root: path(PATH_ACTIVE, PATH_CONTRACTS),
      full: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_CONTRACTS]),
      create: buildPaths(PATH_ACTIVE, [PATH_CONTRACTS, PATH_CREATE]),
      createFull: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_CONTRACTS, PATH_CREATE]),
      view: buildPaths(PATH_ACTIVE, [PATH_CONTRACTS, PATH_ITEM_ID, PATH_VIEW]),
      viewFull: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_CONTRACTS, PATH_ITEM_ID, PATH_VIEW]),
      viewFullGenerate:
        (uid: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_CONTRACTS, uid, PATH_VIEW]),
      edit: buildPaths(PATH_ACTIVE, [PATH_CONTRACTS, PATH_ITEM_ID, PATH_EDIT]),
      editFull: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_CONTRACTS, PATH_ITEM_ID, PATH_EDIT]),
      editFullGenerate:
        (uid: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_CONTRACTS, uid, PATH_EDIT]),
      doc: {
        view: buildPaths(PATH_ACTIVE, [PATH_CONTRACTS, PATH_ITEM_ID, PATH_LIST_ITEM_TYPE, PATH_LIST_ITEM_ID, PATH_VIEW]),
        viewFullGenerate:
          (uid: string, documentId?: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_CONTRACTS, uid, PATH_DOCUMENT, documentId ?? '', PATH_VIEW]),
        edit: buildPaths(PATH_ACTIVE, [PATH_CONTRACTS, PATH_ITEM_ID, PATH_LIST_ITEM_TYPE, PATH_LIST_ITEM_ID, PATH_EDIT]),
        editFullGenerate:
          (uid: string, documentId?: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_CONTRACTS, uid, PATH_DOCUMENT, documentId ?? '', PATH_EDIT]),
      },
    },
    documents: {
      root: path(PATH_ACTIVE, PATH_DOCUMENTS),
      full: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_DOCUMENTS]),
      create: buildPaths(PATH_ACTIVE, [PATH_DOCUMENTS, PATH_CREATE]),
      createFull: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_DOCUMENTS, PATH_CREATE]),
      view: buildPaths(PATH_ACTIVE, [PATH_DOCUMENTS, PATH_ITEM_ID, PATH_VIEW]),
      viewFull: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_DOCUMENTS, PATH_ITEM_ID, PATH_VIEW]),
      viewFullGenerate:
        (uid: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_DOCUMENTS, uid, PATH_VIEW]),
      edit: buildPaths(PATH_ACTIVE, [PATH_DOCUMENTS, PATH_ITEM_ID, PATH_EDIT]),
      editFull: buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_DOCUMENTS, PATH_ITEM_ID, PATH_EDIT]),
      editFullGenerate:
        (uid: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_DOCUMENTS, uid, PATH_EDIT]),
      doc: {
        view: buildPaths(PATH_ACTIVE, [PATH_DOCUMENTS, PATH_ITEM_ID, PATH_LIST_ITEM_TYPE, PATH_LIST_ITEM_ID, PATH_VIEW]),
        viewFullGenerate:
          (uid: string, documentId?: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_DOCUMENTS, uid, PATH_DOCUMENT, documentId ?? '', PATH_VIEW]),
        edit: buildPaths(PATH_ACTIVE, [PATH_DOCUMENTS, PATH_ITEM_ID, PATH_LIST_ITEM_TYPE, PATH_LIST_ITEM_ID, PATH_EDIT]),
        editFullGenerate:
          (uid: string, documentId?: string) => buildPaths(PATH_ROOTS_APP, [PATH_ACTIVE, PATH_DOCUMENTS, uid, PATH_DOCUMENT, documentId ?? '', PATH_EDIT]),
      },
    },
  },
  archive: {
    root: PATH_ARCHIVE,
    rootFull: path(PATH_ROOTS_APP, PATH_ARCHIVE),
    contracts: {
      root: path(PATH_ARCHIVE, PATH_CONTRACTS),
      full: buildPaths(PATH_ROOTS_APP, [PATH_ARCHIVE, PATH_CONTRACTS]),
      view: buildPaths(PATH_ARCHIVE, [PATH_CONTRACTS, PATH_ITEM_ID, PATH_VIEW]),
      viewFull: buildPaths(PATH_ROOTS_APP, [PATH_ARCHIVE, PATH_CONTRACTS, PATH_ITEM_ID, PATH_VIEW]),
      viewFullGenerate: (uid: string) => buildPaths(PATH_ROOTS_APP, [PATH_ARCHIVE, PATH_CONTRACTS, uid, PATH_VIEW]),
      doc: {
        view: buildPaths(PATH_ARCHIVE, [PATH_CONTRACTS, PATH_ITEM_ID, PATH_LIST_ITEM_TYPE, PATH_LIST_ITEM_ID, PATH_VIEW]),
      },
    },
    documents: {
      root: path(PATH_ARCHIVE, PATH_DOCUMENTS),
      full: buildPaths(PATH_ROOTS_APP, [PATH_ARCHIVE, PATH_DOCUMENTS]),
      view: buildPaths(PATH_ARCHIVE, [PATH_DOCUMENTS, PATH_ITEM_ID, PATH_VIEW]),
      viewFull: buildPaths(PATH_ROOTS_APP, [PATH_ARCHIVE, PATH_DOCUMENTS, PATH_ITEM_ID, PATH_VIEW]),
      viewFullGenerate: (uid: string) => buildPaths(PATH_ROOTS_APP, [PATH_ARCHIVE, PATH_DOCUMENTS, uid, PATH_VIEW]),
      doc: {
        view: buildPaths(PATH_ARCHIVE, [PATH_DOCUMENTS, PATH_ITEM_ID, PATH_LIST_ITEM_TYPE, PATH_LIST_ITEM_ID, PATH_VIEW]),
      },
    },
    templates: {
      root: path(PATH_ARCHIVE, PATH_TEMPLATES),
      full: buildPaths(PATH_ROOTS_APP, [PATH_ARCHIVE, PATH_TEMPLATES]),
      view: buildPaths(PATH_ARCHIVE, [PATH_TEMPLATES, PATH_ITEM_ID, PATH_VIEW]),
      viewFull: buildPaths(PATH_ROOTS_APP, [PATH_ARCHIVE, PATH_TEMPLATES, PATH_ITEM_ID, PATH_VIEW]),
      viewFullGenerate: (uid: string) => buildPaths(PATH_ROOTS_APP, [PATH_ARCHIVE, PATH_TEMPLATES, uid, PATH_VIEW]),
      doc: {
        view: buildPaths(PATH_ARCHIVE, [PATH_TEMPLATES, PATH_ITEM_ID, PATH_LIST_ITEM_TYPE, PATH_LIST_ITEM_ID, PATH_VIEW]),
      },
    },
  },
  reports: {
    root: PATH_REPORTS,
    full: path(PATH_ROOTS_APP, PATH_REPORTS),
    contracts: {
      root: path(PATH_REPORTS, PATH_CONTRACTS),
      full: buildPaths(PATH_ROOTS_APP, [PATH_REPORTS, PATH_CONTRACTS]),
    },
    documents: {
      root: path(PATH_REPORTS, PATH_DOCUMENTS),
      full: buildPaths(PATH_ROOTS_APP, [PATH_REPORTS, PATH_DOCUMENTS]),
    },
  },
  analytics: {
    root: PATH_ANALYTICS,
    rootFull: path(PATH_ROOTS_APP, PATH_ANALYTICS),
    contracts: {
      root: path(PATH_ANALYTICS, PATH_CONTRACTS),
      full: buildPaths(PATH_ROOTS_APP, [PATH_ANALYTICS, PATH_CONTRACTS]),
    },
    documents: {
      root: path(PATH_ANALYTICS, PATH_DOCUMENTS),
      full: buildPaths(PATH_ROOTS_APP, [PATH_ANALYTICS, PATH_DOCUMENTS]),
    },
  },
  communication: {
    root: PATH_EMAIL,
    full: path(PATH_ROOTS_APP, PATH_EMAIL),
  },
}
