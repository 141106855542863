/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Paths
import { PATH_APP_DOCS, PATH_APP_AUTH, PATH_APP_MAIN } from 'src/routes';
// Types
import { TypeRouteItem } from 'src/@types';
// Components
import { Loadable, AuthGuard } from 'src/components';
import PlatformAccessGuard from 'src/components/guards/PlatformAccessGuard';
// Layouts
import { DashboardLayout } from 'src/layouts';
// Navigation configuration
import { docsNavBarConfig } from 'src/routes';
// Wrapper
import DashboardPageWithBreadcrumbs from 'src/layouts/dashboard/views/DashboardPageWithBreadcrumbs';
import DocumentPageNewEdit from "src/pages/docs/documents/DocumentPageNewEdit";

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Authentication Pages
const PermissionDeniedPage = Loadable(lazy(() => import('src/pages/access/PagePermissionDenied')));
const DashboardPage = Loadable(lazy(() => import('src/pages/docs/dashboard/DashboardPage')));
const EmailsListPage = Loadable(lazy(() => import('src/pages/docs/communication/EmailsListPage')));
const DocsListPage = Loadable(lazy(() => import('src/pages/docs/views/docs-list-page')));
const DocsListOldPage = Loadable(lazy(() => import('src/pages/docs/documents/DocumentsListPage')));
// const DocumentNewEditOldPage = Loadable(lazy(() => import('src/pages/docs/documents/DocumentPageNewEdit')));
const DocumentFilePage = Loadable(lazy(() => import('src/pages/docs/files/document-file-page')));
const DocumentProcessPage = Loadable(lazy(() => import('src/pages/docs/files/document-process-page')));
const FilesListPage = Loadable(lazy(() => import('src/pages/docs/files/files-list-page')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const docsRoutes: TypeRouteItem = {
  path: PATH_APP_DOCS.root,
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <PlatformAccessGuard path={PATH_APP_MAIN.root}>
        <DashboardLayout navConfig={docsNavBarConfig} />
      </PlatformAccessGuard>
    </AuthGuard>
  ),
  children: [
    // Redirect
    { element: <Navigate to={PATH_APP_DOCS.dashboard.full} replace />, index: true },
    {
      path: PATH_APP_DOCS.active.root,
      element: <Navigate to={PATH_APP_DOCS.active.contracts.full} replace />,
      index: true
    },
    {
      path: PATH_APP_DOCS.archive.root,
      element: <Navigate to={PATH_APP_DOCS.archive.contracts.full} replace />,
      index: true,
    },
    {
      path: PATH_APP_DOCS.reports.root,
      element: <Navigate to={PATH_APP_DOCS.reports.contracts.full} replace />,
      index: true,
    },
    {
      path: PATH_APP_DOCS.analytics.root,
      element: <Navigate to={PATH_APP_DOCS.analytics.contracts.full} replace />,
      index: true,
    },
    // Dashboard
    {
      path: PATH_APP_DOCS.dashboard.root,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Contracts List'}
        onChangeToPath={PATH_APP_DOCS.active.templates.createFull}
        buttonName={'New Template'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        {/*<DocsListOldPage docType={'contracts'} statusType={'active'} />*/}
        <DocsListPage docType={'contracts'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>
    },
    // Contracts
    {
      path: PATH_APP_DOCS.active.contracts.root,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Contracts List'}
        onChangeToPath={PATH_APP_DOCS.active.contracts.createFull}
        buttonName={'New Contract'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        {/*<DocsListOldPage docType={'contracts'} statusType={'active'} />*/}
        <DocsListPage docType={'contracts'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>,
    },
    {
      path: PATH_APP_DOCS.archive.contracts.root,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Archive Contracts List'}
        buttonName={'New Contract'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocsListPage docType={'contracts'} statusType={'archive'} />
      </DashboardPageWithBreadcrumbs>,
    },
    {
      path: PATH_APP_DOCS.active.contracts.create,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Create Contract'}
        onChangeToPath={PATH_APP_DOCS.active.contracts.full}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocumentPageNewEdit isEditPage={false} />
        {/*<FilesListPage isEditPage={true} docType={'contracts'} statusType={'active'} />*/}
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.contracts.edit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Edit Contract'}
        onChangeToPath={PATH_APP_DOCS.active.contracts.viewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FilesListPage isEditPage={true} docType={'contracts'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.contracts.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Contract'}
        onChangeToPath={PATH_APP_DOCS.active.contracts.editFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FilesListPage isEditPage={false} docType={'contracts'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.archive.contracts.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Archived Contract'}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FilesListPage isEditPage={false} docType={'contracts'} statusType={'archive'} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.contracts.doc.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Contract'}
        // onChangeToPath={PATH_APP_DOCS.active.contracts.doc.editFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocumentProcessPage />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.archive.contracts.doc.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Archived Contract'}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <p>archive document view</p>
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.contracts.doc.edit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Edit Contract'}
        onChangeToPath={PATH_APP_DOCS.active.contracts.doc.viewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <p>document edit</p>
      </DashboardPageWithBreadcrumbs>
    },
    // Documents
    {
      path: PATH_APP_DOCS.active.documents.root,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Documents List'}
        onChangeToPath={PATH_APP_DOCS.active.documents.createFull}
        buttonName={'New Document'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocsListPage docType={'documents'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>,
    },
    {
      path: PATH_APP_DOCS.archive.documents.root,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Archived Documents List'}
        buttonName={'New Document'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocsListPage docType={'documents'} statusType={'archive'} />
      </DashboardPageWithBreadcrumbs>,
    },
    {
      path: PATH_APP_DOCS.active.documents.create,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Create Document'}
        onChangeToPath={PATH_APP_DOCS.active.documents.full}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FilesListPage isEditPage={true} docType={'documents'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.documents.edit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Edit Document'}
        onChangeToPath={PATH_APP_DOCS.active.documents.viewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FilesListPage isEditPage={true} docType={'documents'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.documents.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Document'}
        onChangeToPath={PATH_APP_DOCS.active.documents.editFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FilesListPage isEditPage={false} docType={'documents'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.archive.documents.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Archive Document'}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FilesListPage isEditPage={false} docType={'documents'} statusType={'archive'} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.documents.doc.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Document'}
        onChangeToPath={PATH_APP_DOCS.active.documents.doc.editFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <p>document view</p>
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.archive.templates.doc.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Archive Document'}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <p>archive document view</p>
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.documents.doc.edit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Edit Document'}
        onChangeToPath={PATH_APP_DOCS.active.documents.doc.viewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <p>document edit</p>
      </DashboardPageWithBreadcrumbs>
    },
    // Templates
    {
      path: PATH_APP_DOCS.active.templates.root,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Templates List'}
        onChangeToPath={PATH_APP_DOCS.active.templates.createFull}
        buttonName={'New Template'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocsListPage docType={'templates'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>,
    },
    {
      path: PATH_APP_DOCS.archive.templates.root,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Archived Templates List'}
        buttonName={'New Template'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocsListPage docType={'templates'} statusType={'archive'} />
      </DashboardPageWithBreadcrumbs>,
    },
    {
      path: PATH_APP_DOCS.active.templates.create,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Create Template'}
        onChangeToPath={PATH_APP_DOCS.active.templates.full}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FilesListPage isEditPage={true} docType={'templates'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.templates.edit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Edit Template'}
        onChangeToPath={PATH_APP_DOCS.active.templates.viewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FilesListPage isEditPage={true} docType={'templates'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.templates.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Template'}
        onChangeToPath={PATH_APP_DOCS.active.templates.editFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FilesListPage isEditPage={false} docType={'templates'} statusType={'active'} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.archive.templates.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Archive Template'}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FilesListPage isEditPage={false} docType={'templates'} statusType={'archive'} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.templates.doc.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Template'}
        // onChangeToPath={PATH_APP_DOCS.active.templates.doc.editFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DocumentFilePage
          isEditPage={false}
          docType={'template'}
          statusType={'active'}
        />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.archive.templates.doc.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'View Archive Template'}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <p>archive document view</p>
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_DOCS.active.templates.doc.edit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Documents'}
        pageSubTitle={'Edit Template'}
        onChangeToPath={PATH_APP_DOCS.active.templates.doc.viewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <p>document edit</p>
      </DashboardPageWithBreadcrumbs>
    },
    // Reports
    {
      path: PATH_APP_DOCS.reports.contracts.root,
      element: <p>Contracts report</p>,
    },
    {
      path: PATH_APP_DOCS.reports.documents.root,
      element: <p>Documents report</p>,
    },
    // Reports
    {
      path: PATH_APP_DOCS.analytics.contracts.root,
      element: <p>Contracts analytic</p>,
    },
    {
      path: PATH_APP_DOCS.analytics.documents.root,
      element: <p>Documents analytic</p>,
    },
    // Communication - EMAIL -
    {
      path: PATH_APP_DOCS.communication.root,
      element: <PermissionDeniedPage path={PATH_APP_MAIN.root} />
    }
  ]
};

export default docsRoutes;
