// @mui
import Box from '@mui/material/Box';
// types
// components
import { Scrollbar } from 'src/components';
import React, { useEffect, useState } from "react";
import { getMessages } from "src/services/requests/requests";
import { Stack, Typography } from "@mui/material";
import { useAuth } from "src/hooks";
import { formatDistanceToNowStrict } from "date-fns";
//
// import { useMessagesScroll } from './hooks';
// import ChatMessageItem from './chat-message-item';

// ----------------------------------------------------------------------

type Props = {
  docUid: string;
};

export default function ChatMessageRequestList({ docUid }: Props) {
  const [messages, setMessages] = useState<any[]>();
  useEffect(() => {
    getMessages(docUid, setMessages);
  }, []);

  const getMessageItem = (message: any) => {
    const { user } = useAuth();
    const me = message?.userUid === user?.user_uid ?? false;
    return (
      <Stack direction="row" justifyContent={me ? 'flex-end' : 'unset'} sx={{ mb: 5 }}>
        <Stack alignItems="flex-end">
          <Typography
            noWrap
            variant="caption"
            sx={{
            mb: 1,
            color: 'text.disabled',
            ...(!me && {
              mr: 'auto',
            }),
          }}
            >
            {/*{!me && `${firstName},`} &nbsp;*/}
            {formatDistanceToNowStrict(message?.created?.toDate(), {
              addSuffix: true,
            })}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              position: 'relative',
              '&:hover': {
                '& .message-actions': {
                  opacity: 1,
                },
              },
            }}
          >
            <Stack
              sx={{
                p: 1.5,
                minWidth: 48,
                maxWidth: 320,
                borderRadius: 1,
                typography: 'body2',
                bgcolor: 'background.neutral',
                ...(me && {
                  color: 'grey.800',
                  bgcolor: 'primary.lighter',
                }),
              }}
            >
              {message?.message}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    )
  }

  // const slides = messages
  //   .filter((message) => message.contentType === 'image')
  //   .map((message) => ({ src: message.body }));
  //
  // const lightbox = useLightBox(slides);
  //
  return (
    <>
      <Scrollbar sx={{ px: 3, py: 5, height: 1 }}>
        <Box >
          {messages?.map((message) => getMessageItem(message))}
        </Box>
      </Scrollbar>
    </>
  );
}
