import styles from "src/components/InvoiceStyle";
import { Page, View, Document, Image, Link, Text } from "@react-pdf/renderer";

type Props = {
  data: Record<string, any>,
  employees: Record<string, any>[],
  budgets: Record<string, any>[],
}

function parseContent(data: Record<string, any>, employees: Record<string, any>[], budgets: Record<string, any>[]) {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <Text style={styles.col6}>Event: {data.display_name}</Text>
        <Text style={styles.col6}>Destination: {data.location}</Text>
      </View>
      {data.created?.toDate && (<View style={styles.tableRow}>
        <Text style={styles.col6}>Created On: {data.created.toDate().toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}</Text>
        <Text style={styles.col6}>Status: {data.status}</Text>
      </View>)}
      <View style={styles.tableRow}>
        <Text style={styles.col6}>Created By: {employees.find((employee) => employee.user_uid === data.created_by_uid)?.display_name ?? 'N/A'}</Text>
        <Text style={styles.col6}>Responsible Person: {employees.find((employee) => employee.user_uid === data.manager_uid)?.display_name ?? 'N/A'}</Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.col6}># Passengers: {data.passengers}</Text>
        <Text style={styles.col6}>Budget: {budgets.find((budget) => budget.uid === data.budget_uid)?.display_name ?? 'N/A'}</Text>
      </View>
      <View style={styles.tableRow}>
        {data.date_from?.toDate && (<Text style={styles.col12}>Departure Date: {data.date_from.toDate()?.toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}</Text>)}
      </View>
      <View style={styles.tableRow}>
        {data.date_to?.toDate && (<Text style={styles.col12}>Return Date: {data.date_to.toDate().toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }) ?? 'N/A'}</Text>)}
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.col12}>Comment - " {data.description} "</Text>
      </View>
      {/*<View style={[styles.tableRow, styles.mb40]} fixed />*/}
      <View style={[styles.tableRow, styles.mb40]} fixed />
      {data?.meals?.map((meal: Record<string, any>) => (
        <View style={styles.tableRow}>
          {meal?.date?.toDate && (<Text style={styles.col4}>Pick up date / Time: {meal.date.toDate()?.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }) ?? 'N/A'}</Text>)}
          <Text style={styles.col4}>Quantity: {meal?.quantity ?? 'N/A'}</Text>
          <Text style={styles.col4}>Comments: {meal?.description ?? 'N/A'}</Text>
        </View>
      ))}
      {/*<View style={[styles.tableRow, styles.mb40]} fixed />*/}
      {/*{data?.transports?.map((meal: Record<string, any>) => (*/}
      {/*  <View style={styles.tableRow}>*/}
      {/*    <Text style={styles.col4}>Departure date: {meal?.date ?? 'N/A'}</Text>*/}
      {/*    <Text style={styles.col4}>Departure time: {meal?.time_from ?? 'N/A'}</Text>*/}
      {/*    <Text style={styles.col4}>Return time: {meal?.time_to ?? 'N/A'}</Text>*/}
      {/*    <Text style={styles.col4}>From: {meal?.from_location ?? 'N/A'}</Text>*/}
      {/*    <Text style={styles.col4}>To: {meal?.to_location ?? 'N/A'}</Text>*/}
      {/*    <Text style={styles.col4}>Comments: {meal?.description ?? 'N/A'}</Text>*/}
      {/*  </View>*/}
      {/*))}*/}
    </View>
  )
}

export default function RequestPdf({ data, employees, budgets }: Props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.gridContainer, styles.mb40]} fixed>
          <Image source="/images/10UWC_logo_slogan.png" style={{height: 48 }} />
        </View>
        {parseContent(data, employees, budgets)}
      </Page>
    </Document>
  )
}