/**
 * @description - The Bisflow application content's translation
 */
// Common words like "Yes", "No", "Edit"
import enCommonContent from './en_common_words';
const enContentBisflow = {
  common: { ...enCommonContent }
};

export default enContentBisflow;
