import { PATH_APP_ADMIN } from './index';

// Icons
import {
  icon_elements,
  icon_forms,
  icon_user,
  icon_budgets,
  icon_payments,
  icon_invoices,
  icon_box,
  icon_stock,
  icon_requests,
  icon_authorizations,
  icon_documents,
  icon_archive,
  icon_charts,
  icon_reports2,
  icon_analytics,
  icon_store,
  icon_team,
  icon_department,
  icon_process,
  icon_procurement,
} from '../components';

export default [
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_APP_ADMIN.dashboardFull,
        icon: icon_elements
      }
    ]
  },
  {
    subheader: 'finance',
    items: [
      {
        title: 'budgets',
        path: PATH_APP_ADMIN.finance.budgetsFull,
        icon: icon_budgets
      },
      {
        title: 'payments',
        path: PATH_APP_ADMIN.finance.paymentsFull,
        icon: icon_payments
      },
      {
        title: 'invoices',
        path: PATH_APP_ADMIN.finance.invoicesFull,
        icon: icon_invoices
      }
    ]
  },
  {
    subheader: 'reports',
    items: [
      {
        title: 'procurement',
        path: PATH_APP_ADMIN.reports.procurementFull,
        icon: icon_procurement
      },
      {
        title: 'documents',
        path: PATH_APP_ADMIN.reports.documentsFull,
        icon: icon_reports2
      },
      {
        title: 'financial',
        path: PATH_APP_ADMIN.reports.financialFull,
        icon: icon_analytics
      }
    ]
  }
]
