import { getMessagesQuery, queryEmail } from "src/services/documents/queries";
import {
  getFirestore,
  writeBatch,
  getDoc,
  getDocs,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
  query, where
} from "firebase/firestore";
import {
  getRequestsQuery,
  queryNewRequest, queryRequestByUid
} from './queries';

export function sendEmail(event_name: string, content: string, email: string, onSuccess?: VoidFunction) {
  const reference = queryEmail('');
  setDoc(reference, {
    uid: reference.id,
    updated: Timestamp.now(),
    created: Timestamp.now(),
    to: [email],
    message: {
      subject: `Transport request ${event_name}`,
      html: content,
    }
  }).then(() => window.location.reload()).catch(console.log)
}

export function getMessages(docUid: string, onSuccess: (data: any[]) => void) {
  const reference = getMessagesQuery(docUid);
  getDocs(reference).then((querySnapshots) => {
    const allDocuments: any[] = [];
    let lastSnapshot: QueryDocumentSnapshot;
    querySnapshots.forEach((querySnapshot) => {
      const docData = querySnapshot.data();
      if (docData) {
        allDocuments.push(docData);
      }
      lastSnapshot = querySnapshot;
    });
    onSuccess(allDocuments);
  })
    .catch((error) => console.log(error.message));
}

function findRepeatDaysFromStart(start: Date, repeatOptions: Record<string, any>): number[] {
  const { days, endDate } = repeatOptions;
  const end = endDate;
  let currentDate = new Date(start);
  const dayDifferences: number[] = [];

  // Map from day names to their corresponding indices in Date.getDay() (0 for Sunday, 6 for Saturday)
  const dayMap: { [key: string]: number } = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  const repeatDayNumbers = Object.entries(days)
    .filter(([day, isRepeated]) => isRepeated)
    .map(([day]) => { return dayMap[day.toLowerCase()]; });

  while (currentDate <= end) {
    if (repeatDayNumbers.includes(currentDate.getDay())) {
      // Calculate the difference in days and add it to the array
      const differenceInTime = currentDate.getTime() - start.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      dayDifferences.push(differenceInDays);
    }
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dayDifferences;
}

export function createRequest(
  data: Record<string, any>,
  onSuccess: (uid: string) => void,
  onError: (error: string) => void,
) {

  if (data.repeat) {
    const daysDifferences = findRepeatDaysFromStart(data.date_from, data.repeat) ?? [];
    const batch = writeBatch(getFirestore());
    const originalRequestReference = queryNewRequest();
    batch.set(originalRequestReference, {
      ...data,
      active: true,
      visible: true,
      attachments: data.attachments ?? null,
      uid: originalRequestReference.id,
      updated: Timestamp.now(),
      created: Timestamp.now(),
      project_uid: 'ZYUic1Pi8zd1wTpETBop',
      original_request_id: originalRequestReference.id,
    });
    daysDifferences.map((dayDifference: number) => {
      const newFromDate = new Date(data.date_from);
      const newToDate = new Date(data.date_to);

      newFromDate.setDate(newFromDate.getDate() + dayDifference);
      newToDate.setDate(newToDate.getDate() + dayDifference);

      const requestReference = queryNewRequest();
      batch.set(requestReference, {
        ...data,
        date_from: newFromDate,
        date_to: newToDate,
        active: true,
        visible: false,
        attachments: data.attachments ?? null,
        uid: requestReference.id,
        updated: Timestamp.now(),
        created: Timestamp.now(),
        project_uid: 'ZYUic1Pi8zd1wTpETBop',
        original_request_id: originalRequestReference.id,
      });
    });

    batch.commit().then(() => onSuccess(originalRequestReference.id)).catch((error) => onError(error?.message ?? error));
  } else {
    const requestReference = queryNewRequest();
    setDoc(requestReference, {
      ...data,
      active: true,
      visible: true,
      attachments: data.attachments ?? null,
      uid: requestReference.id,
      updated: Timestamp.now(),
      created: Timestamp.now(),
      project_uid: 'ZYUic1Pi8zd1wTpETBop',
    }).then(() => onSuccess(requestReference.id)).catch((error) => onError(error?.message ?? error));
  }
}

export function getRequests(
  userUid: string,
  isSelf: boolean,
  onSuccess: (data: Record<string, any>[]) => void,
  onError: (error: string) => void,
  isRepeatable = true,
) {
  let reference = getRequestsQuery(isRepeatable);
  if (userUid !== 'J8Hp1Ats7zTZDGPKT7cFDDNFNKc2' && userUid !== 'Hb8v80TFXPhEanm1rYqgEnxuVmt1' && userUid !== 'XF7ysIEzFuUmGtgb3U3oEXJju1w2' && userUid !== 'e7P31U2Fb1VgKkThwRSo3lZJs8h1' && userUid !== 'GvjPEa5shLbOdUaCQqAcpzJt6WA2' && userUid !== 'PzZiW9RVUXOGjlhnuImYHtgCHuq2' && userUid !== 'ckH72VWqfeMaIYPwtZHiwSGJ6Rd2') {
    reference = query(
      reference,
      where('created_by_uid', '==', userUid)
    )
  }
  getDocs(reference)
    .then((querySnapshots) => {
      const allRequests: Record<string, any>[] = [];
      querySnapshots.forEach((querySnapshot) => {
        const docData = querySnapshot.data();
        if (docData) {
          allRequests.push(docData);
        }
      });
      onSuccess(allRequests);
    })
    .catch((error) => console.log(error.message));
}

export function getRequestData(requestId: string, onSuccess: (data: Record<string, any>) => void) {
  const documentReference = queryRequestByUid(requestId);
  getDoc(documentReference)
    .then((snapshot) => {
      if (snapshot.exists()) {
        onSuccess(snapshot.data());
      } else {
        console.log("Can't find the document");
      }
    })
    .catch(console.log)
}

export function updateRequest(requestId: string, data: Record<string, any>, onSuccess: (data: Record<string, any>) => void) {
  const documentReference = queryRequestByUid(requestId);
  setDoc(documentReference, {
    ...data,
    created: Timestamp.now(),
  });
  onSuccess(data);
}

export function updateRequestData(requestId: string, data: Record<string, any>, onSuccess: VoidFunction) {
  const documentReference = queryRequestByUid(requestId);
  updateDoc(documentReference, { ...data, updated: Timestamp.now() }).then(onSuccess);
}
