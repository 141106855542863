/**
 * @description - The file contains all queries in order to fetch necessary data from firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firebase related
import { doc, getFirestore, collection, query, where, limit, orderBy } from "firebase/firestore";
// Configurations
import { userPaths, employeesPaths, profilesPaths } from "src/configs";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description -The method returning the reference of the user private data
 * @param userUid <string> - The user uid based on which need to fetch user data
 */
export function getUserPrivateReference(userUid: string) {
  return doc(
    getFirestore(),
    userPaths.COLLECTION_USERS,
    userUid
  );
}

/**
 * @description -The method returning the reference of the company user data
 * @param companyUid <string> - The user uid based on which need to fetch user data
 */
export function getCompanyProfileQuery(companyUid: string) {
  // Getting employee reference
  const profileReference = collection(getFirestore(), profilesPaths.COLLECTION_PROFILES);
  return query(
    profileReference,
    where(profilesPaths.FIELD_ACTIVE, '==', true),
    where(profilesPaths.FIELD_USER_UID, '==', companyUid),
  );
}

/**
 * @description -The method returning the reference of the company user data
 * @param companyUidList <string[]> - The user uid based on which need to fetch user data
 */
export function getCompaniesProfilesQuery(companyUidList: string[]) {
  // Getting employee reference
  const employeeReference = collection(getFirestore(), profilesPaths.COLLECTION_PROFILES);
  return query(
    employeeReference,
    where(profilesPaths.FIELD_ACTIVE, '==', true),
    where(profilesPaths.FIELD_ACCOUNT_TYPE, '==', 'company'),
    where(profilesPaths.FIELD_USER_UID, 'in', companyUidList),
    orderBy(profilesPaths.FIELD_DISPLAY_NAME, 'asc'),
    limit(10),
  );
}

/**
 * @description -The method returning the reference of the user employee data
 * @param userUid <string> - The user uid based on which need to fetch user data
 * @param companyUid <string> - The company uid which data need to fetch
 */
export function getUserEmployeeQuery(userUid: string, companyUid: string) {
  // Getting employee reference
  const employeeReference = collection(getFirestore(), employeesPaths.COLLECTION_EMPLOYEES);
  return query(
    employeeReference,
    where(employeesPaths.FIELD_ACTIVE, '==', true),
    where(employeesPaths.FIELD_COMPANY_UID, '==', companyUid),
    where(employeesPaths.FIELD_USER_UID, '==', userUid),
  );
}
