/**
 * @description - The all services that are related to the documents' data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getDocs,
  getDoc,
  Timestamp,
  addDoc,
  updateDoc,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
// Queries
import {
  queryAllFlowsByCompanyUid,
  queryFlowByUid,
  queryAllStepsByFlowUid,
} from './queries';
import { fetchTotalNumberByCompanyUid } from 'src/services/common';
// Local type
import {
  Flow,
  FlowCategory,
  FlowStatus,
  toFlow,
  Step,
  StepAction,
  toStep,
} from './models';
import { flowsPaths } from "src/configs";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is triggering the total document number fetching process
 * @param company_uid
 * @param onSuccess
 * @param onFail
 * @param active
 */
export function fetchFlowsTotalNumberByCompanyUid(
  company_uid: string,
  onSuccess: (totalNumber: number) => void,
  onFail: (error: string) => void,
  active = true,
) {
  fetchTotalNumberByCompanyUid(
    company_uid,
    flowsPaths.COLLECTION_FLOWS,
    onSuccess,
    onFail,
    active,
  );
}

/**
 * @description - The method is fetching the docs list based on company uid
 * @param user_uid - The user uid that do the fetching
 * @param company_uid - The company uid which docs need to be fetched
 * @param flowCategory - The category type which need to be fetched
 * @param onSuccess - The on success method which need to be triggered when docs fetched
 * @param onFail - The on fail method which need to be triggered when something went wrong
 * @param flowStatus - The status, if it needs custom ordering and filtering
 * @param limitNumber - The paginated total number of each page
 * @param nextPage - The paginated data's page number
 * @param orderField - The order field name, if it needs custom ordering
 * @param orderDirection - The order direction
 * @param active - The document activity status
 */
export function fetchAllFlowsByCompanyUid(
  user_uid: string,
  company_uid: string,
  onSuccess: (docs: Flow[]) => void,
  onFail: (error: string) => void,
  flowCategory?: FlowCategory,
  flowStatus?: FlowStatus,
  limitNumber?: number,
  nextPage?: QueryDocumentSnapshot,
  orderField?: string,
  orderDirection?: "asc" | "desc",
  active?: boolean,
) {
  getDocs(queryAllFlowsByCompanyUid(
    user_uid,
    company_uid,
    flowCategory,
    flowStatus,
    limitNumber,
    nextPage,
    orderField,
    orderDirection,
    active,
  ))
    .then((querySnapshots) => {
      const docs: Flow[] = [];
      let lastSnapshot: QueryDocumentSnapshot;
      querySnapshots.forEach((querySnapshot) => {
        const docData = querySnapshot.data();
        if (docData) {
          docs.push(toFlow(docData));
        }
        lastSnapshot = querySnapshot;
      });
      onSuccess(docs);
    })
    .catch((error) => onFail(error.message))
}

export function fetchFlowByFlowUid(
  flowUid: string,
  onSuccess: (doc: Flow) => void,
  onFail: (error: string) => void,
) {
  const flowReference = queryFlowByUid(flowUid);

  getDoc(flowReference)
    .then((snapshot) => {
      if (snapshot.exists()) {
        onSuccess(toFlow(snapshot.data()));
      } else {
        onFail("Can't find the flow");
      }
    })
    .catch(onFail)
}

export function fetchAllStepsByFlowUid(
  flowUid: string,
  onSuccess: (steps: Step[]) => void,
  onFail: (error: string) => void,
  flowStatus?: FlowStatus,
  limitNumber?: number,
  nextPage?: QueryDocumentSnapshot,
  orderField?: string,
  orderDirection?: "asc" | "desc",
  active?: boolean,
) {
  getDocs(queryAllStepsByFlowUid(flowUid, flowStatus, limitNumber, nextPage, active))
    .then((querySnapshots) => {
      const allSteps: Step[] = [];
      if (querySnapshots) {
        querySnapshots.forEach((querySnapshot) => {
          const docData = querySnapshot.data();
          if (docData) {
            allSteps.push(toStep(docData));
          }
        });
        onSuccess(allSteps);
      } else {
        onFail("Can't get flow detailed info. Something went wrong.");
      }
    })
    .catch((error) => onFail(error.message));
}
