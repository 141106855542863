import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import {
  Page,
  FormProvider,
  RHFSelect,
  RHFUploadSingleFile,
  RHFTextField,
  Label,
  Iconify,
  LoadingScreen, DialogAnimate
} from 'src/components';
import {
  Box, Button,
  Card, CircularProgress,
  Container, DialogActions, DialogContent, DialogTitle,
  Divider,
  Grid, IconButton,
  Stack,
  Step,
  StepConnector,
  StepLabel,
  Stepper, TextField, Tooltip,
  Typography
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TypeCurrencyIsoCode } from "src/@types";
import { useSnackbar } from "notistack";
import { useAuth, useLocalStorage } from "src/hooks";
import { useDispatch, useSelector } from 'src/redux/store';
import { getBudgetsList, getTeamMembersList } from "src/redux/slices";
import { getRequestData, getRequests, sendEmail, updateRequest } from "src/services/requests/requests";
import PurchaseOrderNewEditSection from "src/pages/requests/create/PurchaseOrderNewEditSection";
import PurchaseOrderNewEditSectionTransport from "src/pages/requests/create/PurchaseOrderNewEditTransportSection";
import { DatePicker, DateTimePicker, LoadingButton, TimePicker } from "@mui/lab";
import { transport_email, uwcd_transportation_email } from "src/assets/emails/uwcd_emails";
import { getTeam } from "src/services";
import ChatMessageRequestList from "src/pages/requests/create/chat-request-message-list";
import ChatMessageList from "src/pages/docs/files/chat-message-list";
import ChatMessageInput from "src/pages/docs/files/chat-message-input";
import { PDFDownloadLink } from "@react-pdf/renderer";
import RequestPdf from "src/pages/requests/manage/RequestPdf";
import { steps } from "src/pages/configs";

/**
 * @description - The new request form values
 */
export type NewRequestItemFormValues = {
  display_name: string;
  description: string;
  unit_measure: string;
  quantity: number;
  price: string;
  total: string;
  currency_iso_code: TypeCurrencyIsoCode | null;
  budget_uid: string;
}

export type NewRequestItemMeal = {
  date: string;
  description: string;
  time_from: string;
  time_to: string | null;
  quantity: number;
  price: number;
  currency_iso_code: TypeCurrencyIsoCode
}

export type NewRequestItemTransportation = {
  date: string;
  time_from: string;
  time_to: string | null;
  from_location: string;
  to_location: string;
  passengers_numbers: number;
  price: string;
  currency_iso_code: TypeCurrencyIsoCode;
  description: string;
}

/**
 * @description - The new request form values
 */
export type NewRequestFormValues = {
  uid: string;
  company_uid: string;
  platform: string;
  // Created person
  created_by_uid: string;
  // Fields
  display_name: string;
  description: string;
  location: string;
  date_from: string;
  date_to: string | null;
  budget_uid: string;
  // Optional, manager / responsible person
  manager_uid: string;
  // items
  meals: NewRequestItemMeal[],
  transports: NewRequestItemTransportation[],
}

/**
 * @description - The style of the page
 */
const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  padding: theme.spacing(12, 0),
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
}));

export function ManageRequestWithId() {
  const { id } = useParams();
  // Snackbar
  const { enqueueSnackbar } = useSnackbar();
  // Selected company
  const [selectedCompany, setSelectedCompany] = useLocalStorage('company', {});
  // Get user and companies
  const { user } = useAuth();
  // Dispatch
  const dispatch = useDispatch();

  // The global state
  const { requestForms, isLoading, error } = useSelector((state) => state.forms);
  const { budgets, isBudgetLoading, error: budgetError } = useSelector((state) => state.budgets);
  const { employees } = useSelector((state) => state.team);
  const [ requestData, setRequestData ] = useState<Record<string, any> | null>(null);
  const [ isChanged, setIsChanged ] = useState(false);
  const [ isAvailabilityDialog, setAvailabilityDialog ] = useState(false);
  const [requestsData, setRequestsData] = useState<Record<string, any>[]>([])
  /**
   * @description - attaching the listener for the forms
   */
  useEffect(() => {
    if (user?.uid && selectedCompany) {
      dispatch(getBudgetsList(selectedCompany?.user_uid));
      dispatch(getTeamMembersList(selectedCompany?.user_uid));
      getRequests(user?.uid, false, setRequestsData, console.log);
    }

    if (id) {
      getRequestData(id, setRequestData);
    }
  }, [dispatch, selectedCompany, user, id]);

  // ======================================== REQUEST SCHEMA and FORM SETUP ========================================= //
  /**
   * @description - The new request schema.
   * @param formId <string> - Contains the form id in order to select fetch form values and data.
   */
  const NewRequestSchema = Yup.object().shape({
    platform: Yup.string().required('Please select the request type'),
  });
  // The default value of the request
  const defaultValues = {
    platform: '',
    company_uid: 'rdovUxTkloT2QAHL2q8CqRsKJ552',
    project_uid: 'ZYUic1Pi8zd1wTpETBop',
    created_by_uid: user?.user_uid ?? '',
    display_name: '',
    description: '',
    location: '',
    date_from: '',
    date_to: null,
    budget_uid: '',
    manager_uid: '',
    meals: [],
    transports: [],
  };
  // The form's methods and values initialization
  const methods = useForm<NewRequestFormValues>({
    resolver: yupResolver(NewRequestSchema),
    defaultValues,
  });
  // Getting all necessary functions and variables from form's methods object
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;
  /**
   * @description - The values of the form
   */
  const values = watch();

  /**
   * @description - The onSubmit click handler method
   * @param data - The new request form object.
   */
  const onSubmit = handleSubmit((data: NewRequestFormValues) => {
    // createRequest(
    //   data,
    //   () => { enqueueSnackbar('Successfully created', { variant: 'success' }); window.location.reload(); },
    //   (error) => enqueueSnackbar(`Error: Something went wrong - ${error}`, { variant: 'error' })
    // );
  });
  function QontoStepIcon({ active, completed }: { active: boolean; completed: boolean }) {
    return (
      <Box
        sx={{
          zIndex: 9,
          width: 24,
          height: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: active ? 'primary.main' : 'text.disabled',
        }}
      >
        {completed ? (
          <Iconify
            icon={'eva:checkmark-fill'}
            sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }}
          />
        ) : (
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: 'currentColor',
            }}
          />
        )}
      </Box>
    );
  }
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    top: 10,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
    '& .MuiStepConnector-line': {
      borderTopWidth: 2,
      borderColor: theme.palette.divider,
    },
    '&.Mui-active, &.Mui-completed': {
      '& .MuiStepConnector-line': {
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const getStepFromStatus = () => {
    if (requestData?.status === 'requester') {
      return 0;
    }

    if (requestData?.status === 'facility') {
      return 2;
    }

    if (requestData?.status === 'done') {
      return 3;
    }

    return 1;
  }

  const getSltEmail = () => {
    if (requestData) {
      const currentEmployee = employees.find(employee => employee.user_uid === requestData.created_by_uid);
      if (currentEmployee?.clt && currentEmployee.clt.length > 0) {
        return currentEmployee.clt[0];
      }
    }
    return 'none';
  }
  const getAssignedEmail = (isNext = 0) => {
    const step = getStepFromStatus() + isNext;
    if (step === 2) {
      return steps[step].email;
    }

    if (step === 1 && requestData) {
      const currentEmployee = employees.find(employee => employee.user_uid === requestData.created_by_uid);
      if (currentEmployee?.clt && currentEmployee.clt.length > 0) {
        return currentEmployee.clt[0];
      } else {
        return steps[step].email;
      }
    }

    if (step <= 0 || step === 3) {
      if (requestData && employees) {
        const currentEmployee = employees.find(employee => employee.user_uid === requestData.created_by_uid);
        if (currentEmployee) {
          return currentEmployee.email;
        }
      }
    }

    return 'none';
    // return 'vladimirdanielyan@gmail.com'
  }

  function replacePlaceholders(template: any, data: Record<string, any>) {
    let result = template;

    // Loop over the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        // Create a new RegExp that matches the placeholder
        const placeholder = new RegExp(`{{__${key}__}}`, 'g');
        // Replace all occurrences of the placeholder with the data value
        result = result.replace(placeholder, data[key]);
      }
    }

    return result;
  }

  const onConfirm = () => {
    if (id && requestData) {
      const currentStatusIndex = getStepFromStatus();
      const status = steps[currentStatusIndex + 1].status;

      // Example data object with dynamic values
      const data = {
        name: 'John Doe',
        action_description: 'Please review the following details:',
        Item_1: 'Laptop - Dell XPS 13',
        price_item_1: '$1000',
        Item_2: 'Monitor - Dell UltraSharp',
        price_item_2: '$300',
        t_price: '$1300',
        created_by: 'Jane Doe',
        email: 'jane.doe@example.com',
        department: 'IT Department',
        created_date: '2023-03-29'
      };

      // Use the function to get the HTML with replaced values
      const updatedEmail = replacePlaceholders(uwcd_transportation_email, data);

      updateRequest(id, {
        ...requestData,
        status: !isChanged ? status : 'requester',
        [`confirmed_date_${status}`]: Date.now(),
      }, setRequestData);
      const email = getAssignedEmail(isChanged ? -500 : 1);
      const emailText = uwcd_transportation_email;
      sendEmail(requestData?.display_name ?? '', transport_email.replaceAll('href="https://bisflow.io"', `href="https://request.bisflow.io/app/requests/manage/${requestData?.uid}"`), email)
    }
  }

  const onDecline = () => {
    if (id && requestData) {
      const currentStatusIndex = getStepFromStatus();
      const status = 'requester';
      // Example data object with dynamic values
      const data = {
        name: 'John Doe',
        action_description: 'Please review the following details:',
        Item_1: 'Laptop - Dell XPS 13',
        price_item_1: '$1000',
        Item_2: 'Monitor - Dell UltraSharp',
        price_item_2: '$300',
        t_price: '$1300',
        created_by: 'Jane Doe',
        email: 'jane.doe@example.com',
        department: 'IT Department',
        created_date: '2023-03-29'
      };

      // Use the function to get the HTML with replaced values
      const updatedEmail = replacePlaceholders(uwcd_transportation_email, data);

      updateRequest(id, {
        ...requestData,
        status: !isChanged ? status : 'requester',
        active: false,
        [`confirmed_date_${status}`]: Date.now(),
      }, setRequestData);
      const email = getAssignedEmail(-500);
      const emailText = uwcd_transportation_email;
      sendEmail(requestData?.display_name ?? '', transport_email.replaceAll('href="https://bisflow.io"', `href="https://request.bisflow.io/app/requests/manage/${requestData?.uid}"`), email)
    }
  }

  const handleFieldChange = (field: string, value: string | any) => {
    if (user?.email === 'vladimirdanielyan@gmail.com' || user?.email === 's.hakobyan@uwcdilijan.am' || user?.email === 't.babayan@uwcdilijan.am' || user?.email === 'l.asoyan@uwcdilijan.am') {
      setRequestData({
        ...requestData,
        [field]: value,
      });
      // if (field === 'description') {
      //   return;
      // }
      setIsChanged(true);
    }
  }

  const toggleAvailability = () => {
    setAvailabilityDialog(!isAvailabilityDialog);
  }

  if (isLoading || !requestData) {
    return <LoadingScreen />;
  }
  // ================================================================================================================ //

  return (
    <Page title="Request">
      <DialogAnimate fullScreen={true} open={isAvailabilityDialog} onClose={() => setAvailabilityDialog(false)}>
        <DialogTitle>Transports booked at the date</DialogTitle>
        <DialogContent>
          {requestsData?.map((requestInfo) => {
            // if (requestInfo?.status === 'requester') {}
            // return null
            if ((requestInfo?.uid !== requestData?.uid && requestData?.date_from?.toDate && requestInfo?.date_to?.toDate && requestInfo?.date_from?.toDate && requestData.date_from.toDate() <= requestInfo.date_to.toDate() && requestData.date_from.toDate() >= requestInfo.date_from.toDate())) {
              return <p>{requestInfo.display_name} <a href={`https://request.bisflow.io/app/requests/manage/${requestInfo.uid}`}>{employees?.find((employee) => employee.user_uid === requestInfo.created_by_uid)?.email}</a> <span style={{fontWeight: 700}}>{requestInfo.date_from.toDate()?.toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}</span> - <span style={{fontWeight: 700}}>{requestInfo.date_to.toDate()?.toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}</span> {requestInfo?.passengers} {requestInfo.location}</p>
            }

            return null;
          })}
        </DialogContent>
        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />

          <Button variant="outlined" color="inherit" onClick={() => setAvailabilityDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </DialogAnimate>
      <Container maxWidth={false}>
        <ContentStyle>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <Stepper
                  alternativeLabel
                  activeStep={getStepFromStatus()}
                  connector={<QontoConnector/>}
                  sx={{ mt: 2, mb: 2 }}
                >
                  {steps.map((label, stepIndex) => (
                    <Step key={label.label}>
                      <StepLabel
                        StepIconComponent={QontoStepIcon}
                        sx={{
                          '& .MuiStepLabel-label': {
                            typography: 'subtitle2',
                            color: 'text.disabled'
                          }
                        }}
                      >
                        {label.label} {stepIndex === 1 ? getSltEmail() : ''}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12} md={3}>
                <Stack
                  spacing={2}
                  direction={{xs: 'column', sm: 'row'}}
                  justifyContent="space-between"
                  alignItems={{sm: 'center'}}
                >
                  <Stack direction="row" spacing={1}>
                    {getAssignedEmail() === user?.email && requestData?.active === true && (
                      <Stack direction="row" spacing={1}>
                        <LoadingButton
                          color="success"
                          size="small"
                          variant="contained"
                          loading={false}
                          onClick={onConfirm}
                        >
                          {isChanged ? 'Submit Changes' : 'Confirm'}
                        </LoadingButton>
                      </Stack>
                    )}
                    {getAssignedEmail() === user?.email && getStepFromStatus() !== 0 && !isChanged && (
                      <Stack direction="row" spacing={1}>
                        <LoadingButton
                          color="error"
                          size="small"
                          variant="contained"
                          loading={false}
                          onClick={onDecline}
                        >
                          Decline
                        </LoadingButton>
                      </Stack>
                    )}
                    {(user?.email === 'vladimirdanielyan@gmail.com' || user?.email === 's.hakobyan@uwcdilijan.am') && (
                      <Stack direction="row" spacing={1}>
                        <LoadingButton
                          color="info"
                          size="small"
                          variant="outlined"
                          loading={false}
                          onClick={toggleAvailability}
                        >
                          Availability
                        </LoadingButton>
                      </Stack>
                    )}
                    <PDFDownloadLink
                      document={<RequestPdf data={requestData ?? {}} employees={employees ?? []} budgets={budgets ?? []} />}
                      fileName={`${requestData?.display_name ?? 'none'}.pdf`}
                      style={{textDecoration: 'none'}}
                    >
                      {({loading}) => (
                        <Tooltip title="Download">
                          <IconButton>
                            {loading ? (
                              <CircularProgress size={24} color="inherit"/>
                            ) : (
                              <Iconify icon={'eva:download-fill'}/>
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                    </PDFDownloadLink>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={9}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} direction={'column'}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                      <RHFTextField
                        size="small"
                        value={requestData?.display_name}
                        onChange={(e) => { handleFieldChange('display_name', e.target.value); }}
                        label="Event"
                        InputLabelProps={{ shrink: true }}
                        name={'display_name'}
                      />
                      <RHFTextField
                        size="small"
                        value={requestData?.location}
                        onChange={(e) => { handleFieldChange('location', e.target.value); }}
                        label="Destination"
                        InputLabelProps={{ shrink: true }}
                        name={'location'}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                      <DatePicker
                        label="Departure Date"
                        value={requestData.date_from?.toDate ? requestData.date_from.toDate() : requestData.date_from}
                        onChange={(e) => handleFieldChange('date_from', e) }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                          />
                        )}
                      />
                      <TimePicker
                        label="* Departure time"
                        value={requestData.date_from?.toDate ? requestData.date_from.toDate() : requestData.date_from}
                        onChange={(e) => {
                          handleFieldChange('date_from', e ?? null);
                        }}
                        renderInput={(params) => <TextField {...params} style={{ minWidth: '120px' }} />}
                        ampm={false}
                      />
                      {requestData.date_to?.toDate && (<DatePicker
                        label="Return Date"
                        value={requestData.date_to?.toDate()}
                        onChange={(e) => handleFieldChange('date_to', e.target.value) }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                          />
                        )}
                      />)}
                      {requestData.time_to?.toDate && (<TimePicker
                        label="Return time"
                        value={requestData.time_to?.toDate()}
                        onChange={(e) => handleFieldChange('time_to', e.target.value ?? null) }
                        renderInput={(params) => <TextField {...params} style={{ minWidth: '120px' }} />}
                        ampm={false}
                      />)}
                      {/*<RHFTextField*/}
                      {/*  size="small"*/}
                      {/*  value={requestData?.date_to?.toDate()?.toDateString()}*/}
                      {/*  onChange={(e) => { handleFieldChange('date_to', e.target.value); }}*/}
                      {/*  label="Return date"*/}
                      {/*  InputLabelProps={{ shrink: true }}*/}
                      {/*  name={'date_to'}*/}
                      {/*/>*/}
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                      <RHFTextField
                        size="small"
                        name={'passengers'}
                        value={requestData.passengers}
                        onChange={(e) => handleFieldChange('passengers', e.target.value ?? null) }
                        type={'number'}
                        label="* Number of passengers"
                        InputLabelProps={{ shrink: true }}
                      />
                      <Typography variant="body2" component="div">Budget: {budgets?.find((budget) => budget.uid === requestData.budget_uid)?.display_name ?? 'N/A'}</Typography>
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                      <Typography variant="body2" component="div">Requester Person: {employees?.find((employee) => employee.user_uid === requestData.created_by_uid)?.display_name ?? 'N/A'}</Typography>
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                      <Typography variant="body2" component="div">Responsible Person: {requestData.manager_uid}</Typography>
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                      <RHFTextField
                        multiline={true}
                        rows={5}
                        size="small"
                        name={'description'}
                        value={requestData.description}
                        onChange={(e) => handleFieldChange('description', e.target.value ?? null) }
                        label="Comments about the trip/transport"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card>
                  <Stack
                    sx={{
                      width: 1,
                      height: 1,
                      overflow: 'hidden',
                    }}
                  >
                    <ChatMessageRequestList docUid={id ?? 'none'}/>

                    <ChatMessageInput
                      recipients={[]}
                      onAddRecipients={() => {
                      }}
                      //
                      selectedConversationId={''}
                      disabled={false}
                    />
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={9}>
              {/*  {requestData?.transports?.length > 0 && (<Card>*/}
              {/*    <Box sx={{ p: 3 }}>*/}
              {/*      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>*/}
              {/*        Transport Details:*/}
              {/*      </Typography>*/}
              {/*      <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>*/}
              {/*        {requestData?.transports?.map((item: Record<string, any>) => (*/}
              {/*          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>*/}
              {/*            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>*/}
              {/*              <RHFTextField*/}
              {/*                size="small"*/}
              {/*                name={`time_from`}*/}
              {/*                value={item?.time_from}*/}
              {/*                onChange={(e) => {}}*/}
              {/*                label="Departure time"*/}
              {/*                InputLabelProps={{ shrink: true }}*/}
              {/*              />*/}
              {/*              <RHFTextField*/}
              {/*                size="small"*/}
              {/*                name={`time_to`}*/}
              {/*                value={item?.time_to}*/}
              {/*                onChange={(e) => {}}*/}
              {/*                label="Return time"*/}
              {/*                InputLabelProps={{ shrink: true }}*/}
              {/*              />*/}
              {/*            </Stack>*/}
              {/*            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>*/}
              {/*              <RHFTextField*/}
              {/*                multiline={true}*/}
              {/*                rows={4}*/}
              {/*                size="small"*/}
              {/*                name={`description`}*/}
              {/*                value={item?.description}*/}
              {/*                onChange={(e) => {}}*/}
              {/*                label="Comments about the trip/transport"*/}
              {/*                InputLabelProps={{ shrink: true }}*/}
              {/*              />*/}
              {/*            </Stack>*/}
              {/*          </Stack>*/}
              {/*        ))}*/}
              {/*      </Stack>*/}
              {/*    </Box>*/}
              {/*  </Card>)}*/}
              {/*  <Divider sx={{ mt: 1, mb: 1 }} />*/}
                {requestData?.meals?.length > 0 && (<Card>
                  <Box sx={{ p: 3 }}>
                    <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
                      Meal request:
                    </Typography>
                    <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
                      {requestData?.meals?.map((item: Record<string, any>, index: number) => (
                        <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
                          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                            {item?.date?.toDate && (<DateTimePicker
                              label="* Pick up date / time"
                              value={item.date?.toDate()}
                              onChange={(e) => {
                                // const finalMeals = [...requestData.meals];
                                // finalMeals[index] = { ...item, date: e } ?? null;
                                // handleFieldChange('meals', finalMeals ?? null)
                              }}
                              // onChange={(newValue) => { newValue.setSeconds(59); setValue(`meals[${index}].date`, newValue)} }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                />
                              )}
                              ampm={false}
                            />)}
                            <RHFTextField
                              size="small"
                              value={item?.quantity ?? ''}
                              onChange={(e) => {
                                const finalMeals = [...requestData.meals];
                                finalMeals[index] = { ...item, quantity: e.target.value } ?? null;
                                handleFieldChange('meals', finalMeals ?? null)
                              }}
                              label="Quantity"
                              sx={{ maxWidth: { md: 90 } }}
                              InputLabelProps={{ shrink: true }}
                              name={'quantity'}
                            />
                            <RHFTextField
                              size="small"
                              value={item?.description ?? ''}
                              onChange={(e) => {
                                const finalMeals = [...requestData.meals];
                                finalMeals[index] = { ...item, description: e.target.value } ?? null;
                                handleFieldChange('meals', finalMeals ?? null)
                              }}
                              label="Comments about meal"
                              InputLabelProps={{ shrink: true }}
                              name={'description'}
                            />
                          </Stack>
                        </Stack>
                      ))}
                    </Stack>
                  </Box>
                </Card>)}
              </Grid>
            </Grid>
          </FormProvider>
        </ContentStyle>
      </Container>
    </Page>
  )
}