/**
 * @description - The file is to generate the redux store, in order to pass to redux for the future execution.
 * The redux is the global state, and the Redux's store is needed to set up the initial state of the redux.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// The module is allowing to modify the store configuration
import { configureStore } from '@reduxjs/toolkit';
// The dispatch's hooks to use during the store initialization, in order to call all the slices
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';
// The PersistGate component is the recommended way to delay rendering until persistence is complete.
import { persistStore, persistReducer } from 'redux-persist';
// Initial state of all reducers
import { rootPersistConfig, rootReducer } from './rootReducer';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

// Creating/Initializing the store for the React redux
const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});
// Initializing the store persistor. The PersistGate component is the recommended way to delay rendering until
// persistence is complete.
const persistor = persistStore(store);
// Interfaces/Types for the reducers
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
// Getting the dispatch object from the initialized store
const { dispatch } = store;
// Initializing the useDispatch hook in order to use on upper level
const useDispatch = () => useAppDispatch<AppDispatch>();
// Initializing the useSelector hook in order to use on upper level
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;
// The main exports contains store, persistor, dispatch and dispatch's hooks
export { store, persistor, dispatch, useSelector, useDispatch };
