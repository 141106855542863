/**
 * @description - Routes variable for different platforms. Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Layouts
import { CompactLayout } from 'src/layouts';
// Paths
import { PATH_APP_MAIN } from 'src/routes/paths';
// Types
import { TypeRouteItem } from 'src/@types';
// Components
import { Loadable, GuestGuard, LogoBariFoundation } from 'src/components';
// Config
import { default as erebuniConfig } from './erebuni-nav-config';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Application selection Pages
const ErebuniLandingPage = Loadable(lazy(() => import('./ErebuniLandingPage')));
const ErebuniComingSoonPage = Loadable(lazy(() => import('./ErebuniComingSoonPage')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Authentication routes list, contains list of RouteItem type objects.
 */
const erebuniRoutes: TypeRouteItem = {
  path: PATH_APP_MAIN.root,
  element: (
    <GuestGuard path={PATH_APP_MAIN.rootFull}>
      <CompactLayout logo={LogoBariFoundation} />
    </GuestGuard>
  ),
  children: [
    { element: <Navigate to={PATH_APP_MAIN.landingFull} replace />, index: true },
    // Applications
    {
      path: PATH_APP_MAIN.landing,
      element: <ErebuniLandingPage />
    },
    {
      path: PATH_APP_MAIN.comingSoon,
      element: <ErebuniComingSoonPage />
    }
  ]
};
export const notFoundRoute = { path: '*', element: <Navigate to="/404" replace /> };

export default erebuniRoutes;
