/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Paths
import { PATH_APP_ADMIN, PATH_APP_AUTH , PATH_APP_MAIN } from './index';
// Types
import { TypeRouteItem } from 'src/@types';
// Components
import { Loadable, AuthGuard, AdminRoleGuard } from 'src/components';
import PlatformAccessGuard from 'src/components/guards/PlatformAccessGuard';
// Layouts
import { DashboardLayout } from 'src/layouts';
// Wrapper
import DashboardPageWithBreadcrumbs from 'src/layouts/dashboard/views/DashboardPageWithBreadcrumbs';
// Navigation configuration
import { financeNavBarConfig } from './index';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Authentication Pages
const AdminDashboardPage = Loadable(lazy(() => import('src/pages/admin/dashboard/DashboardPage')));
const MaintenancePage = Loadable(lazy(() => import('src/pages/maintenance/index')));
// Budgets
const BudgetsListView = Loadable(lazy(() => import('src/pages/admin/budgets/BudgetsListView')));
const InvoicesView = Loadable(lazy(() => import('src/pages/finances/invoices-page')));
const PaymentsView = Loadable(lazy(() => import('src/pages/finances/payments-page')));
const ProcurementView = Loadable(lazy(() => import('src/pages/finances/procurement-page')));
const BudgetCreateEditPage = Loadable(lazy(() => import('src/pages/admin/budgets/BudgetCreateEditPage')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const financeRoutes: TypeRouteItem = {
  path: PATH_APP_ADMIN.root,
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <PlatformAccessGuard path={PATH_APP_MAIN.root}>
        <DashboardLayout navConfig={financeNavBarConfig} />
      </PlatformAccessGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_APP_ADMIN.dashboardFull} replace />, index: true },
    // Dashboard
    {
      path: PATH_APP_ADMIN.dashboard,
      element: <AdminDashboardPage />,
    },

    // Finances section - REDIRECT -
    {
      path: PATH_APP_ADMIN.finance.root,
      element: <Navigate to={PATH_APP_ADMIN.finance.paymentsFull} replace />,
      index: true
    },
    // Finances section - BUDGETS -
    {
      path: PATH_APP_ADMIN.finance.budgets,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Budgets'}
        pageSubTitle={'Budgets'}
        onChangeToPath={PATH_APP_ADMIN.finance.budgetCreateFull}
        buttonName={'New Budget'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <BudgetsListView />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.finance.budgetCreate,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Budgets'}
        pageSubTitle={'Create Budget'}
        onChangeToPath={PATH_APP_ADMIN.finance.budgetsFull}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <BudgetCreateEditPage isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.finance.budgetEdit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Budgets'}
        pageSubTitle={'Edit Budget'}
        onChangeToPath={PATH_APP_ADMIN.finance.budgetViewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <BudgetCreateEditPage isEditPage={true} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.finance.budgetViewRedirect,
      // ToDo fix redirect to view page, add corresponded component
      element: <Navigate to={PATH_APP_ADMIN.finance.budgetsFull} replace />,
      index: true
    },
    {
      path: PATH_APP_ADMIN.finance.budgetView,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Budgets'}
        pageSubTitle={'Budget'}
        onChangeToPath={PATH_APP_ADMIN.finance.budgetEditFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <BudgetCreateEditPage isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    // Finances section - PAYMENTS -
    {
      path: PATH_APP_ADMIN.finance.payments,
      element: <PaymentsView />
    },
    // Finances section - INVOICES -
    {
      path: PATH_APP_ADMIN.finance.invoices,
      element: <InvoicesView />
    },

    // Requests section - REDIRECT -
    {
      path: PATH_APP_ADMIN.requests.root,
      element: <Navigate to={PATH_APP_ADMIN.requests.goodsFull} replace />
    },
    // Requests section - STOCKS -
    {
      path: PATH_APP_ADMIN.requests.goods,
      element: <ProcurementView />
    },

    // Documents section - REDIRECT -
    {
      path: PATH_APP_ADMIN.documents.root,
      element: <Navigate to={PATH_APP_ADMIN.documents.activeFull} replace />
    },
    // Documents section - BUDGETS -
    {
      path: PATH_APP_ADMIN.documents.authorizations,
      element: <MaintenancePage />
    },
    // Documents section - PAYMENTS -
    {
      path: PATH_APP_ADMIN.documents.active,
      element: <MaintenancePage />
    },
    // Documents section - INVOICES -
    {
      path: PATH_APP_ADMIN.documents.archive,
      element: <MaintenancePage />
    },

    // Reports section - REDIRECT -
    {
      path: PATH_APP_ADMIN.reports.root,
      element: <Navigate to={PATH_APP_ADMIN.reports.financialFull} replace />
    },
    // Reports section - BUDGETS -
    {
      path: PATH_APP_ADMIN.reports.procurement,
      element: <ProcurementView />
    },
    // Reports section - PAYMENTS -
    {
      path: PATH_APP_ADMIN.reports.documents,
      element: <MaintenancePage />
    },
    // Reports section - INVOICES -
    {
      path: PATH_APP_ADMIN.reports.financial,
      element: <MaintenancePage />
    },
  ]
};

export default financeRoutes;
