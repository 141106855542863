/**
 * @description - The document new edit form
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// react
import { useMemo, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
// form
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// UI
import { Box, Card, Grid, Stack, MenuItem, Typography, Autocomplete, Chip, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Components
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  Label,
  RHFUploadAvatar,
} from 'src/components';
import { useAuth } from "src/hooks";
import {
  fetchAllDocumentsByDocUid,
  fetchDocumentByDocumentUid,
  saveNewContracts
} from "src/services/documents/documents";
// Types
// import { TypeProject, TypeDocument } from 'src/@types';
// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export type DocumentManagementFormProps = {
  projectUid: string,
  templateUid: string,
  data?: Record<string, any>,
}

interface DocumentNewProps {
  currentDocument: null,
  projects: Record<string, any>[],
  templates: Record<string, any>[],
  onProjectChange: (projectUid: string) => void,
  onSuccessSubmit: (document: DocumentManagementFormProps) => void,
}

/**
 * @description - The document new edit form
 * @constructor
 */
export default function DocumentFormNew({ onProjectChange, currentDocument, projects, templates, onSuccessSubmit }: DocumentNewProps) {
  // Notification bar
  const { enqueueSnackbar } = useSnackbar();
  const NewDocumentSchema = Yup.object().shape({
    projectUid: Yup.string().required('Project should be defined'),
    templateUid: Yup.string().required('Template should be defined'),
  });
  const [contractContent, setContractContent] = useState<Record<string, any> | null>(null);
  const defaultValues = useMemo(() => ({
    projectUid: '',
    templateUid: '',
  }), [currentDocument]);

  const methods = useForm<DocumentManagementFormProps>({
    resolver: yupResolver(NewDocumentSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  // Creating a listener to the documentation values change
  const values = watch();

  useEffect(() => {
    if (values?.templateUid) {
      fetchAllDocumentsByDocUid(values.templateUid, (data) => {
        const currentContractUid = data?.[0]?.uid;
        if (currentContractUid) {
          fetchDocumentByDocumentUid('rdovUxTkloT2QAHL2q8CqRsKJ552', values.templateUid, currentContractUid, setContractContent, console.log);
        }
      }, console.log);
    }
  }, [values?.templateUid]);

  const { user } = useAuth();

  const submitTheForm = () => {
    const templateDataFound = templates.find((template) => template.uid === values.templateUid);
    const newContractData = {
      active: true,
      category: 'contract',
      company_uid: 'rdovUxTkloT2QAHL2q8CqRsKJ552',
      created_by_uid: user?.user_uid,
      updated_by_uid: user?.user_uid,
      description: 'Contract',
      // @ts-ignore
      display_name: `SSST 2024 ${values?.display_name ?? 'na'}`,
      documents_number: 2,
      languages: ['hy', 'en'],
      project_uid: 'QZmlYu0qCD8bPKtQotWH',
      status: 'active',
      template_uid: values?.templateUid ?? 'na',
    };

    saveNewContracts(newContractData, contractContent, () => window.location.reload());
  }

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                <RHFSelect
                  fullWidth
                  name="projectUid"
                  label="Project"
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                >
                  {projects?.map((currentProject: any) => (
                    <MenuItem
                      key={currentProject?.uid}
                      value={currentProject?.uid}
                      onClick={() => onProjectChange(currentProject?.uid)}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      }}
                    >
                      {currentProject.display_name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
              {/* Show the template selection component */}
              {values?.projectUid?.length > 0 && (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                <RHFSelect
                  fullWidth
                  name="templateUid"
                  label="Template"
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                >
                  {templates?.map((currentProject) => (
                    <MenuItem
                      key={currentProject.uid}
                      value={currentProject.uid}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      }}
                    >
                      {currentProject.display_name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>)}
              {values?.templateUid?.length > 0 && ([
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="email" label="Customer email" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="display_name" label="Պայմանագիր համար # / Contract #" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="full_name_am" label="Անուն ազգանուն" />
                  <RHFTextField name="full_name_en" label="Name/Surname" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="citizen_am" label="քաղաքացի" />
                  <RHFTextField name="citizen_en" label="citizen" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="birth_date_am" label="Ծննդյան օր" />
                  <RHFTextField name="birth_date_en" label="Birth Date" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="passport_am" label="անձնագիր" />
                  <RHFTextField name="passport_en" label="passport" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="passport_given_date_am" label="կողմից ամսաթիվը" />
                  <RHFTextField name="passport_given_date_en" label="passport given date" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="passport_given_by_am" label="կողմից" />
                  <RHFTextField name="passport_given_by_en" label="by" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="topic_am" label="առարկա" />
                  <RHFTextField name="topic_en" label="topic" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="topic_date_am" label="տեսակը և ամսաթիվը" />
                  <RHFTextField name="topic_date_en" label="type and date" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="date_from_am" label="մեկնարկի ամսաթիվը" />
                  <RHFTextField name="date_to_en" label="ավարտի ամսաթվը" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="cost_am" label="արժեքը" />
                  <RHFTextField name="cost_en" label="cost" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="exchange_am" label="փոխանակում" />
                  <RHFTextField name="exchange_en" label="exchange" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="bank_am" label="բանկ" />
                  <RHFTextField name="bank_en" label="bank" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="country_am" label="Երկիր" />
                  <RHFTextField name="country_en" label="country" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="city_am" label="Քաղաք" />
                  <RHFTextField name="city_en" label="city" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="iban_am" label="Հաշվեհամար" />
                  <RHFTextField name="iban_en" label="iban" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <RHFTextField name="swift_am" label="Բանկի կոդ" />
                  <RHFTextField name="swift_en" label="swift code" />
                </Stack>),
                (<Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <Button onClick={submitTheForm}>Submit and Create</Button>
                </Stack>)
              ])}
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
