/**
 * @description - The file contains all queries in order to fetch necessary data from
 * firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getFirestore,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  collection,
  Timestamp, doc,
} from 'firebase/firestore';
// Paths
import { departmentsPaths } from 'src/configs';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export function queryFetchDepartments(
  companyUid: string,
  limitNumber?: number,
) {
  // The document reference in which the query should do fetching
  const departmentsDbReference = collection(getFirestore(), departmentsPaths.COLLECTION_ENTITIES);
  // Returning the query
  return query(
    departmentsDbReference,
    // Checking company based documents
    where(departmentsPaths.FIELD_COMPANY_UID, '==', companyUid),
    // Checking status based documents - processing/template/archive/removed
    where(departmentsPaths.FIELD_ACTIVE, '==', true),
    where(departmentsPaths.FIELD_ENTITY_TYPE, '==', 'department'),
    // Ordering by created date
    orderBy(departmentsPaths.FIELD_DISPLAY_NAME, 'asc'),
    // If no limit provided the default is 25
    // limit(limitNumber ?? 25),
  );
}

export function getNewDepartmentReference() {
  // The document reference in which the query should do fetching
  return collection(getFirestore(), departmentsPaths.COLLECTION_ENTITIES);
}

export function getDepartmentReference(uid: string) {
  // The document reference in which the query should do fetching
  return doc(getFirestore(), departmentsPaths.COLLECTION_ENTITIES, uid);
}
