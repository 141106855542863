/**
 * @description - The file contains all queries in order to fetch necessary data from
 * firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getFirestore,
  collection,
} from 'firebase/firestore';
// Paths
import { subscriptionsPaths } from 'src/configs';
// Converters
import { EmailSubscription } from 'src/services/subscriptions/models';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is returning the reference of the new subscription
 */
export function querySubscriptionReference() {
  return collection(getFirestore(), subscriptionsPaths.COLLECTION_SUBSCRIPTIONS);
}
