/**
 * @description - The collapse drawer hook
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

import { useContext } from 'react';
import { CollapseDrawerContext } from '../contexts';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

const useCollapseDrawer = () => useContext(CollapseDrawerContext);

export default useCollapseDrawer;
