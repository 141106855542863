/**
 * @description - The mounted reference hook. To return the status of the mounted references as a state
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import { useRef, useEffect } from 'react';

/**
 * @description - The wrapper component
 */
export default function useIsMountedRef() {
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  return isMounted;
}
