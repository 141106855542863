/**
 * @description - Loader Screen component. Displaying loader with animation.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

import { useEffect, useState, useRef } from 'react';
import { m } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, SxProps, Container, Typography, Button } from '@mui/material';
// Local components
import { LogoBisflowGreen, MotionContainer, ProgressBar } from '../index';
// Local Pages
import PagePermissionDenied from 'src/pages/access/PagePermissionDenied';
// Configs
import { LOADER_SHORT_TIME, LOADER_LONG_TIME } from 'src/configs/common';
import { Link as RouterLink } from "react-router-dom";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The root style of the Loading screen
 */
const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

/**
 * @description - The style of the permission denied page
 */
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

type LoadingScreenProps = {
  isDashboard?: boolean;
  isLongWaiting?: boolean;
  sx?: SxProps;
};

export default function LoadingScreen({ isDashboard, isLongWaiting, ...other }: LoadingScreenProps) {
  const [ isTimePassed, setIsTimePassed ] = useState<boolean>(false);
  const isMounted = useRef(false)

  // Tracking for the component mount state
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  useEffect(() =>  {
    setTimeout(() => {
      if (isMounted?.current) {
        setIsTimePassed(true);
      }
    }, isLongWaiting ? LOADER_LONG_TIME : LOADER_LONG_TIME);
  }, [isMounted?.current]);

  return (
    <>
      <ProgressBar />

      {!isDashboard && !isTimePassed && (
        <RootStyle {...other}>
          <m.div
            initial={{ rotateY: 0 }}
            animate={{ rotateY: 360 }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeatDelay: 1,
              repeat: Infinity,
            }}
          >
            <LogoBisflowGreen sx={{ width: 64, height: 64 }}/>
          </m.div>

          <Box
            component={m.div}
            animate={{
              scale: [1.2, 1, 1, 1.2, 1.2],
              rotate: [270, 0, 0, 270, 270],
              opacity: [0.25, 1, 1, 1, 0.25],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
            sx={{
              width: 100,
              height: 100,
              borderRadius: '25%',
              position: 'absolute',
              border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
            }}
          />

          <Box
            component={m.div}
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
              rotate: [0, 270, 270, 0, 0],
              opacity: [1, 0.25, 0.25, 0.25, 1],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{
              ease: 'linear',
              duration: 3.2,
              repeat: Infinity,
            }}
            sx={{
              width: 120,
              height: 120,
              borderRadius: '25%',
              position: 'absolute',
              border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
            }}
          />
        </RootStyle>
      )}
      {isTimePassed && (
        <Container component={MotionContainer}>
          <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
            <Typography variant="h4" paragraph>
              Loading of the page takes too long.
            </Typography>
            <Typography variant="h4" paragraph>
              Please check the internet connection or contact with us
            </Typography>
            <Typography variant="h2" paragraph>
              support@bisflow.io
            </Typography>
            <Button
              to={'mailto:support@bisflow.io?subject=Site is loading too long'}
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Report issue
            </Button>

            {/* ToDo add reporting */}
          </ContentStyle>
        </Container>
      )}
    </>
  );
}
