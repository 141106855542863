/**
 * @description - The settings context
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import React, { ReactNode, createContext } from 'react';
// hooks
import { useLocalStorage } from 'src/hooks';
// utils
import { getColorPresets, colorPresets, defaultPreset } from 'src/utils/getColorPresets';
// config
import { defaultSettings } from 'src/configs';
// @type
import { TypeThemeMode, TypeThemeColorPresets, TypeSettingsContextProps } from 'src/@types';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

const initialState: TypeSettingsContextProps = {
  ...defaultSettings,
  onChangeMode: () => {},
  onToggleMode: () => {},
  onChangeColor: () => {},
  onToggleStretch: () => {},
  onResetSetting: () => {},
  setColor: defaultPreset,
  colorOption: []
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
  children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeColorPresets: initialState.themeColorPresets,
    themeStretch: initialState.themeStretch
  });

  const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeMode: (event.target as HTMLInputElement).value as TypeThemeMode
    });
  };

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light'
    });
  };

  const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeColorPresets: (event.target as HTMLInputElement).value as TypeThemeColorPresets
    });
  };

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch
    });
  };

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      themeStretch: initialState.themeStretch,
      themeColorPresets: initialState.themeColorPresets
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        onToggleMode,
        // Color
        onChangeColor,
        setColor: getColorPresets(settings.themeColorPresets),
        colorOption: colorPresets.map((color) => ({
          name: color.name,
          value: color.main
        })),
        // Stretch
        onToggleStretch,
        // Reset Setting
        onResetSetting
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
