/**
 * @description - The Auth Layout page's styles
 */

// ========================================================================== //
// ================================= Modules ================================ //
// ========================================================================== //

// Material UI's
import { styled } from '@mui/material/styles';

// ========================================================================== //
// ================================= Logic ================================== //
// ========================================================================== //

/**
 * @description - The wrapper div style of the Main Layout
 */
export const MainLayoutStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: 64,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: 92,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));
