/**
 * @description - Routes variable for different platforms. Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Layouts
import { MainLayout } from '../layouts';
// Paths
import { PATH_APP_AUTH, PATH_APP_MAIN } from './index';
// Types
import { TypeRouteItem } from '../@types';
// Components
import { Loadable, AuthGuard } from '../components';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Application selection Pages
const ApplicationSelectionPage = Loadable(lazy(() => import('../pages/apps/ApplicationSelection')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Authentication routes list, contains list of RouteItem type objects.
 */
const mainRoutes: TypeRouteItem = {
  path: PATH_APP_MAIN.root,
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_APP_MAIN.appsFull} replace />, index: true },
    // Applications
    {
      path: PATH_APP_MAIN.apps,
      element: <ApplicationSelectionPage />
    }
  ]
};
export const notFoundRoute = { path: '*', element: <Navigate to="/404" replace /> };

export default mainRoutes;
