/**
 * @description - The file contains all queries in order to fetch necessary data from
 * firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import {
  getFirestore,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  collection,
  doc,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
// Paths
import { flowsPaths, DEFAULT_NUMBER_OF_DATA } from 'src/configs';
// Converters
import { FlowCategory, FlowStatus, FlowStatusEnum, FlowCategoryEnum, StepActionEnum, Step, StepAction } from 'src/services/flows/models';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is generating firestore query in order to fetch contracts/acts/documents from appropriate
 * collection.
 * @param companyUid - The company uid which project is related to.
 * @param userUid - The user uid which is doing the request
 * @param flowCategory - The category type that need to be fetched
 * @param flowStatus - The status type
 * @param limitNumber - The result limit in order to make it faster
 * @param nextPage - The pagination
 * @param orderField - The field order that need to be parsed
 * @param orderDirection - The order by direction
 * @param active - the document status
 */
export function queryAllFlowsByCompanyUid(
  userUid: string,
  companyUid: string,
  flowCategory: FlowCategory = FlowCategoryEnum.all,
  flowStatus: FlowStatus = FlowStatusEnum.all,
  limitNumber: number = DEFAULT_NUMBER_OF_DATA,
  nextPage?: QueryDocumentSnapshot,
  orderField:
    | typeof flowsPaths.FIELD_DISPLAY_NAME
    | typeof flowsPaths.FIELD_CREATED
    | typeof flowsPaths.FIELD_PROJECT_UID
    | typeof flowsPaths.FIELD_STATUS = flowsPaths.FIELD_CREATED,
  orderDirection: 'asc' | 'desc' = 'desc',
  active = true,
) {
  // Returning the query
  let flowsQuery = query(
    collection(getFirestore(), flowsPaths.COLLECTION_FLOWS),
    where(flowsPaths.FIELD_ACTIVE, '==', active),
    // Checking company based documents
    where(flowsPaths.FIELD_COMPANY_UID, '==', companyUid),
    // ToDo add permission based query (permission groups)
    // where()
    // Ordering by created date
    orderBy(orderField, orderDirection),
    // If no limit provided the default is 150
    limit(limitNumber),
  );
  if (flowCategory && flowCategory !== FlowCategoryEnum.all) {
    // Checking the category type
    where(flowsPaths.FIELD_CATEGORY, '==', flowCategory);
  }
  if (flowStatus && flowStatus !== FlowStatusEnum.all) {
    flowsQuery = query(flowsQuery, where(flowsPaths.FIELD_STATUS, '==', flowStatus));
  }
  // The paginated result
  if (nextPage) {
    flowsQuery = query(flowsQuery, startAfter(nextPage));
  }
  return flowsQuery;
}

/**
 * @description - The method is returning the flow query path by uid
 * @param flowUid - The selected document's uid
 */
export function queryFlowByUid(flowUid: string) {
  return doc(getFirestore(), flowsPaths.COLLECTION_FLOWS, flowUid);
}

/**
 * @description - The method is returning the query for the steps list
 * @param flowUid - The top UID from which documents need to be fetched
 * @param status - The status
 * @param limitNumber - The content limit
 * @param nextPage - The data pagination number
 * @param active - The document status
 * @param actionType - The action type, if it is needed to query based on type
 */
export function queryAllStepsByFlowUid(
  flowUid: string,
  status: FlowStatus = FlowStatusEnum.all,
  limitNumber: number = DEFAULT_NUMBER_OF_DATA,
  nextPage?: QueryDocumentSnapshot,
  active = true,
  actionType: StepAction = StepActionEnum.all,
) {
  // Returning the query
  let stepsQuery = query(
    collection(getFirestore(), flowsPaths.COLLECTION_FLOWS, flowUid, flowsPaths.SUB_COLLECTION_STEPS),
    // Active documents
    where(flowsPaths.FIELD_ACTIVE, '==', active),
    // ToDo add permission based query (permission groups)
    // where()
    // Ordering by created date
    orderBy(flowsPaths.FIELD_ORDER, 'asc'),
    // If no limit provided the default is 150
    limit(limitNumber),
  );
  if (status && status !== FlowStatusEnum.all) {
    stepsQuery = query(stepsQuery, where(flowsPaths.FIELD_STATUS, '==', status));
  }
  if (actionType && actionType !== StepActionEnum.all) {
    stepsQuery = query(
      stepsQuery,
      where(flowsPaths.FIELD_ACTION, '==', actionType),
    );
  }
  if (nextPage) {
    stepsQuery = query(stepsQuery, startAfter(nextPage));
  }

  return stepsQuery;
}
