/**
 * @description - The document presenter component that renders the content of the document
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Material Components
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  Link,
  TableContainer,
} from '@mui/material';
// @mui
import { styled, useTheme } from '@mui/material/styles';
// Local types
// import { TypeDocument, TypeDocumentContentItem } from 'src/@types';
import { Image } from 'src/components';
// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The style for the row
 */
const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

type Props = {
  // document?: TypeDocument | null;
  // contents?: TypeDocumentContentItem[] | null;
  // requisites?: TypeDocumentContentItem[] | null;
  // headers?: TypeDocumentContentItem[] | null;
  // footers?: TypeDocumentContentItem[] | null;
};
//
export default function DocumentPresenterView() {
//
//   const parseContent = () => {
//     const contentData: any = [];
//     if (contents && document && document.languages) {
//       document.languages.map((language) => {
//         const languageContent: any = [];
//         contents.map((content, index) => {
//           const rowContent: any = [];
//           content.values[`${language}`].map((wordContent, wordIndex) => {
//             rowContent.push(<Box
//               onClick={() => console.log('test')}
//               sx={wordContent?.editable ? wordContent?.value ? {bgcolor: 'rgba(98,141,69,0.94)'} : {bgcolor: '#ffff46'} : null}
//               component="span"
//               fontWeight={wordContent.style === 'bold' ? 'fontWeightMedium' : 'fontWeightRegular'}
//             >
//               {wordContent?.value ?? wordContent?.placeholder}
//             </Box>)
//             rowContent.push(' ')
//           })
//           languageContent.push(
//             <Typography
//               variant={content.type === 'title' ? 'h5' : 'body2'}
//               key={`${language}_${index}`}
//               sx={{ textAlign: content.format, mt: 2 }}
//             >
//               {rowContent}
//             </Typography>
//           )
//         });
//         contentData.push(
//           <Grid item xs={12} sm={12 / document.languages.length} sx={{ mb: 5 }}>
//             {languageContent}
//           </Grid>
//         )
//       })
//     }
//     return contentData;
//   };
//
//   const parseRequisites = () => {
//     const contentData: any = [];
//     if (requisites && document && document.languages) {
//       document.languages.map((language) => {
//         const languageContent: any = [];
//         requisites.map((content, index) => {
//           const rowContent: any = [];
//           content.values[`${language}`].map((wordContent, wordIndex) => {
//             rowContent.push(<Box
//               onClick={() => console.log('test')}
//               sx={wordContent?.editable ? wordContent?.value ? {bgcolor: 'rgba(98,141,69,0.94)'} : {bgcolor: '#ffff46'} : null}
//               component="span"
//               fontWeight={wordContent.style === 'bold' ? 'fontWeightMedium' : 'fontWeightRegular'}
//             >
//               {wordContent?.value ?? wordContent?.placeholder}
//             </Box>)
//             rowContent.push(' ')
//           })
//           languageContent.push(
//             <Typography
//               variant={content.type === 'title' ? 'h5' : 'body2'}
//               key={`${language}_${index}`}
//               sx={{ textAlign: content.format, mt: 2 }}
//             >
//               {rowContent}
//             </Typography>
//           )
//         });
//         contentData.push(
//           <Grid item xs={12} sm={12 / document.languages.length} sx={{ mb: 5 }}>
//             {languageContent}
//           </Grid>
//         )
//       })
//     }
//     return contentData;
//   };
//
//   const parseHeader = () => {
//     const contentData: any = [];
//     if (headers && document && document.languages) {
//       document.languages.map((language) => {
//         let languageContent: any = '';
//         headers.map((content, index) => {
//           content.values[`${language}`].map((wordContent, wordIndex) => {
//             languageContent = (
//               <Image
//                 disabledEffect
//                 visibleByDefault
//                 alt={wordContent.placeholder}
//                 src={wordContent.value}
//                 sx={{ maxWidth: 360 }}
//               />
//             )
//           })
//         });
//         contentData.push(
//           <Grid item xs={12} sm={12 / document.languages.length} sx={{ mb: 5, mt: 5 }}>
//             {languageContent}
//           </Grid>
//         )
//       })
//     }
//     return contentData;
//   };
//
//   const parseFooter = () => {
//     const contentData: any = [];
//     if (footers && document && document.languages) {
//       const languageContent: any = [];
//       footers.map((content, index) => {
//         const rowContent: any = [];
//         content.values['am'].map((wordContent, wordIndex) => {
//           if (content.type !== 'link') {
//             rowContent.push(<Box
//               onClick={() => console.log('test')}
//               sx={wordContent?.editable ? wordContent?.value ? {bgcolor: 'rgba(98,141,69,0.94)'} : {bgcolor: '#ffff46'} : null}
//               component="span"
//               fontWeight={wordContent.style === 'bold' ? 'fontWeightMedium' : 'fontWeightRegular'}
//             >
//               {wordContent?.value ?? wordContent?.placeholder}
//             </Box>)
//             rowContent.push(' ')
//           } else {
//             rowContent.push(<Link
//               href={wordContent?.value}
//             >
//               {wordContent?.placeholder}
//             </Link>)
//           }
//         })
//         languageContent.push(
//           <Typography
//             variant={content.type === 'title' ? 'h5' : 'body2'}
//             key={`am_${index}`}
//             sx={{ textAlign: content.format, mt: 2 }}
//           >
//             {rowContent}
//           </Typography>
//         )
//       });
//       contentData.push(
//         <Grid item xs={12} sm={12} sx={{ mb: 5 }}>
//           {languageContent}
//         </Grid>
//       )
//     }
//     return contentData;
//   };
//
//   if (document && contents) {
//     const header = parseHeader();
//     const footer = parseFooter();
//     return (
//       <Box sx={{p: 3}}>
//         <Divider variant={'fullWidth'} sx={{ borderStyle: 'dashed' }} />
//         <Grid container spacing={3}>
//           {header}
//           {parseContent()}
//           {parseRequisites()}
//           {footer}
//         </Grid>
//       </Box>
//     )
//   }
//   return (
//     <Box sx={{p: 3}}>
//       <Typography variant="h6" sx={{color: 'text.disabled', mb: 3, textAlign: 'center'}}>
//         No Any Document Selected
//       </Typography>
//     </Box>
//   )
};
