/**
 * @description - The navigation bar's help section
 */
// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// @mui
import { Stack, Button, Typography } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_APP_HELP } from '../../../routes';
// assets
import { DocIllustration } from '../../../assets';
// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export default function NavbarHelp() {
  const { user } = useAuth();

  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      <DocIllustration sx={{ width: 1 }} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          Hi, {user?.display_name}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need help?
          <br /> Please check our help center
        </Typography>
      </div>

      <Button href={PATH_APP_HELP.rootFull} target="_blank" rel="noopener" variant="contained">
        Help Center
      </Button>
    </Stack>
  );
}
