/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Paths
import { PATH_APP_ADMIN, PATH_APP_AUTH , PATH_APP_MAIN } from './index';
// Types
import { TypeRouteItem } from 'src/@types';
// Components
import { Loadable, AuthGuard, AdminRoleGuard } from 'src/components';
import PlatformAccessGuard from 'src/components/guards/PlatformAccessGuard';
// Layouts
import { DashboardLayout } from 'src/layouts';
// Wrapper
import DashboardPageWithBreadcrumbs from 'src/layouts/dashboard/views/DashboardPageWithBreadcrumbs';
// Navigation configuration
import { adminNavBarConfig } from './index';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Access Pages
const MaintenancePage = Loadable(lazy(() => import('src/pages/maintenance/index')));
const PermissionDeniedPage = Loadable(lazy(() => import('src/pages/access/PagePermissionDenied')));
// Authentication Pages
const AdminDashboardPage = Loadable(lazy(() => import('src/pages/admin/dashboard/DashboardPage')));
const TeamPageView = Loadable(lazy(() => import('src/pages/admin/team/TeamPageView')));
const PersonPageView = Loadable(lazy(() => import('src/pages/admin/team/PersonPageView')));
const PersonPageNewEdit = Loadable(lazy(() => import('src/pages/admin/team/PersonPageNewEdit')));
// Processes
const FormsListPage = Loadable(lazy(() => import('src/pages/admin/forms/FormsListPage')));
const FormCreateEditPage = Loadable(lazy(() => import('src/pages/admin/forms/FormCreateEditPage')));
const FlowsListPage = Loadable(lazy(() => import('src/pages/admin/flows/flows-list-page')));
const FlowCreateEditPage = Loadable(lazy(() => import('src/pages/admin/flows/flow-page-new-edit')));
// Departments
const DepartmentsListView = Loadable(lazy(() => import('src/pages/admin/departments/DepartmentsListView')));
const DepartmentCreateEditPage = Loadable(lazy(() => import('src/pages/admin/departments/DepartmentCreateEditPage')));
// Groups
const GroupsListView = Loadable(lazy(() => import('src/pages/admin/groups/GroupsListView')));
const GroupCreateEditPage = Loadable(lazy(() => import('src/pages/admin/groups/GroupCreateEditPage')));
// Budgets
const BudgetsListView = Loadable(lazy(() => import('src/pages/admin/budgets/BudgetsListView')));
const BudgetCreateEditPage = Loadable(lazy(() => import('src/pages/admin/budgets/BudgetCreateEditPage')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const adminRoutes: TypeRouteItem = {
  path: PATH_APP_ADMIN.root,
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <AdminRoleGuard path={PATH_APP_MAIN.root}>
        <PlatformAccessGuard path={PATH_APP_MAIN.root}>
          <DashboardLayout navConfig={adminNavBarConfig} />
        </PlatformAccessGuard>
      </AdminRoleGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_APP_ADMIN.dashboardFull} replace />, index: true },
    // Dashboard
    {
      path: PATH_APP_ADMIN.dashboard,
      element: <AdminDashboardPage />,
    },
    // Process section - REDIRECT -
    {
      path: PATH_APP_ADMIN.processes.root,
      element: <Navigate to={PATH_APP_ADMIN.processes.formsFull} replace />,
      index: true
    },
    // Process section - FORMS -
    {
      path: PATH_APP_ADMIN.processes.forms,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Forms'}
        pageSubTitle={'Forms List'}
        onChangeToPath={PATH_APP_ADMIN.processes.formCreateFull}
        buttonName={'Create Form'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        {/*<FormsListPage />*/}
        <PermissionDeniedPage path={PATH_APP_MAIN.root} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.processes.formCreate,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Forms'}
        pageSubTitle={'Create Form'}
        onChangeToPath={PATH_APP_ADMIN.processes.formsFull}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        {/*<FormCreateEditPage isEditPage={false} />*/}
        <PermissionDeniedPage path={PATH_APP_MAIN.root} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.processes.formEdit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Forms'}
        pageSubTitle={'Edit Form'}
        onChangeToPath={PATH_APP_ADMIN.processes.formViewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        {/*<FormCreateEditPage isEditPage={true} />*/}
        <PermissionDeniedPage path={PATH_APP_MAIN.root} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.processes.formViewRedirect,
      // ToDo fix redirect to view page, add corresponded component
      element: <Navigate to={PATH_APP_ADMIN.processes.formsFull} replace />,
      index: true
    },
    {
      path: PATH_APP_ADMIN.processes.formView,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Forms'}
        pageSubTitle={'Form'}
        onChangeToPath={PATH_APP_ADMIN.processes.formEditFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        {/*<FormCreateEditPage isEditPage={false} />*/}
        <PermissionDeniedPage path={PATH_APP_MAIN.root} />
      </DashboardPageWithBreadcrumbs>
    },
    // Process section - WORKFLOWS -
    {
      path: PATH_APP_ADMIN.processes.flows.root,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Flows'}
        pageSubTitle={'Flows List'}
        onChangeToPath={PATH_APP_ADMIN.processes.flows.createFull}
        buttonName={'Create Flow'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FlowsListPage />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.processes.flows.create,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Flows'}
        pageSubTitle={'Create Flow'}
        onChangeToPath={PATH_APP_ADMIN.processes.flows.full}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        {/*<FlowCreateEditPage isEditPage={false} />*/}
        <PermissionDeniedPage path={PATH_APP_MAIN.root} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.processes.flows.edit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Flows'}
        pageSubTitle={'Edit Flow'}
        onChangeToPath={PATH_APP_ADMIN.processes.flows.viewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        {/*<FlowCreateEditPage isEditPage={true} />*/}
        <PermissionDeniedPage path={PATH_APP_MAIN.root} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.processes.flows.viewRedirect,
      // ToDo fix redirect to view page, add corresponded component
      element: <Navigate to={PATH_APP_ADMIN.processes.flows.full} replace />,
      index: true
    },
    {
      path: PATH_APP_ADMIN.processes.flows.view,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Flows'}
        pageSubTitle={'Flow'}
        onChangeToPath={PATH_APP_ADMIN.processes.flows.editFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <FlowCreateEditPage isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    // Personnel section - REDIRECT -
    {
      path: PATH_APP_ADMIN.personnel.root,
      element: <Navigate to={PATH_APP_ADMIN.personnel.teamFull} replace />,
      index: true,
    },
    // Personnel section - TEAM -
    {
      path: PATH_APP_ADMIN.personnel.team,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Team'}
        pageSubTitle={'Team'}
        onChangeToPath={PATH_APP_ADMIN.personnel.personnelCreateFull}
        buttonName={'New Member'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <TeamPageView />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.personnel.personnelCreate,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Team'}
        pageSubTitle={'Create Member'}
        onChangeToPath={PATH_APP_ADMIN.personnel.teamFull}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <PersonPageNewEdit isEditPage={false} />
        {/*<PermissionDeniedPage path={PATH_APP_MAIN.root} />*/}
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.personnel.personnelEdit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Team'}
        pageSubTitle={'Edit Member'}
        onChangeToPath={PATH_APP_ADMIN.personnel.personViewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <PersonPageNewEdit isEditPage={true} />
        {/*<PermissionDeniedPage path={PATH_APP_MAIN.root} />*/}
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.personnel.personViewRedirect,
      // ToDo fix redirect to view page, add corresponded component
      element: <Navigate to={PATH_APP_ADMIN.personnel.teamFull} replace />,
      index: true
    },
    {
      path: PATH_APP_ADMIN.personnel.personView,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Team'}
        pageSubTitle={'Member'}
        onChangeToPath={PATH_APP_ADMIN.personnel.personnelEditFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <PersonPageNewEdit isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    // Personnel section - DEPARTMENTS
    {
      path: PATH_APP_ADMIN.personnel.departments,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Departments'}
        pageSubTitle={'Departments List'}
        onChangeToPath={PATH_APP_ADMIN.personnel.departmentCreateFull}
        buttonName={'New Department'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DepartmentsListView />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.personnel.departmentCreate,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Departments'}
        pageSubTitle={'Create Department'}
        onChangeToPath={PATH_APP_ADMIN.personnel.departmentsFull}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DepartmentCreateEditPage isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.personnel.departmentEdit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Departments'}
        pageSubTitle={'Edit Department'}
        onChangeToPath={PATH_APP_ADMIN.personnel.departmentViewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DepartmentCreateEditPage isEditPage={true} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.personnel.departmentViewRedirect,
      // ToDo fix redirect to view page, add corresponded component
      element: <Navigate to={PATH_APP_ADMIN.personnel.departmentsFull} replace />,
      index: true
    },
    {
      path: PATH_APP_ADMIN.personnel.departmentView,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Departments'}
        pageSubTitle={'Department'}
        onChangeToPath={PATH_APP_ADMIN.personnel.departmentEditFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <DepartmentCreateEditPage isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    // Personnel section - DEPARTMENTS -
    {
      path: PATH_APP_ADMIN.personnel.groups,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Groups'}
        pageSubTitle={'Groups'}
        onChangeToPath={PATH_APP_ADMIN.personnel.groupCreateFull}
        buttonName={'New Group'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <GroupsListView />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.personnel.groupCreate,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Groups'}
        pageSubTitle={'Create Group'}
        onChangeToPath={PATH_APP_ADMIN.personnel.groupsFull}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <GroupCreateEditPage isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.personnel.groupEdit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Groups'}
        pageSubTitle={'Edit Group'}
        onChangeToPath={PATH_APP_ADMIN.personnel.groupViewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <GroupCreateEditPage isEditPage={true} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.personnel.groupViewRedirect,
      // ToDo fix redirect to view page, add corresponded component
      element: <Navigate to={PATH_APP_ADMIN.personnel.groupsFull} replace />,
      index: true
    },
    {
      path: PATH_APP_ADMIN.personnel.groupView,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Groups'}
        pageSubTitle={'Group'}
        onChangeToPath={PATH_APP_ADMIN.personnel.groupEditFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <GroupCreateEditPage isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },

    // Finances section - REDIRECT -
    {
      path: PATH_APP_ADMIN.finance.root,
      element: <Navigate to={PATH_APP_ADMIN.finance.paymentsFull} replace />,
      index: true
    },
    // Finances section - BUDGETS -
    {
      path: PATH_APP_ADMIN.finance.budgets,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Budgets'}
        pageSubTitle={'Budgets'}
        onChangeToPath={PATH_APP_ADMIN.finance.budgetCreateFull}
        buttonName={'New Budget'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <BudgetsListView />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.finance.budgetCreate,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Budgets'}
        pageSubTitle={'Create Budget'}
        onChangeToPath={PATH_APP_ADMIN.finance.budgetsFull}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <BudgetCreateEditPage isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.finance.budgetEdit,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Budgets'}
        pageSubTitle={'Edit Budget'}
        onChangeToPath={PATH_APP_ADMIN.finance.budgetViewFullGenerate}
        buttonName={'Cancel'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <BudgetCreateEditPage isEditPage={true} />
      </DashboardPageWithBreadcrumbs>
    },
    {
      path: PATH_APP_ADMIN.finance.budgetViewRedirect,
      // ToDo fix redirect to view page, add corresponded component
      element: <Navigate to={PATH_APP_ADMIN.finance.budgetsFull} replace />,
      index: true
    },
    {
      path: PATH_APP_ADMIN.finance.budgetView,
      element: <DashboardPageWithBreadcrumbs
        pageTitle={'Budgets'}
        pageSubTitle={'Budget'}
        onChangeToPath={PATH_APP_ADMIN.finance.budgetEditFullGenerate}
        buttonName={'Edit'}
        platformName={(process.env.REACT_APP_NAME ?? 'BISFLOW').toLowerCase()}
      >
        <BudgetCreateEditPage isEditPage={false} />
      </DashboardPageWithBreadcrumbs>
    },
    // Finances section - PAYMENTS -
    {
      path: PATH_APP_ADMIN.finance.payments,
      element: <MaintenancePage />
    },
    // Finances section - INVOICES -
    {
      path: PATH_APP_ADMIN.finance.invoices,
      element: <MaintenancePage />
    },

    // Inventory section - REDIRECT -
    {
      path: PATH_APP_ADMIN.inventory.root,
      element: <Navigate to={PATH_APP_ADMIN.inventory.goodsFull} replace />
    },
    // Inventory section - STOCKS -
    {
      path: PATH_APP_ADMIN.inventory.stocks,
      element: <MaintenancePage />
    },
    // Inventory section - GOODS -
    {
      path: PATH_APP_ADMIN.inventory.goods,
      element: <MaintenancePage />
    },

    // Providers section - REDIRECT -
    {
      path: PATH_APP_ADMIN.vendors.root,
      element: <Navigate to={PATH_APP_ADMIN.vendors.manageFull} replace />
    },
    // Providers section - STOCKS -
    {
      path: PATH_APP_ADMIN.vendors.manage,
      element: <MaintenancePage />
    },

    // Requests section - REDIRECT -
    {
      path: PATH_APP_ADMIN.requests.root,
      element: <Navigate to={PATH_APP_ADMIN.requests.goodsFull} replace />
    },
    // Requests section - STOCKS -
    {
      path: PATH_APP_ADMIN.requests.goods,
      element: <MaintenancePage />
    },

    // Documents section - REDIRECT -
    {
      path: PATH_APP_ADMIN.documents.root,
      element: <Navigate to={PATH_APP_ADMIN.documents.activeFull} replace />
    },
    // Documents section - BUDGETS -
    {
      path: PATH_APP_ADMIN.documents.authorizations,
      element: <MaintenancePage />
    },
    // Documents section - PAYMENTS -
    {
      path: PATH_APP_ADMIN.documents.active,
      element: <MaintenancePage />
    },
    // Documents section - INVOICES -
    {
      path: PATH_APP_ADMIN.documents.archive,
      element: <MaintenancePage />
    },

    // Reports section - REDIRECT -
    {
      path: PATH_APP_ADMIN.reports.root,
      element: <Navigate to={PATH_APP_ADMIN.reports.financialFull} replace />
    },
    // Reports section - BUDGETS -
    {
      path: PATH_APP_ADMIN.reports.procurement,
      element: <MaintenancePage />
    },
    // Reports section - PAYMENTS -
    {
      path: PATH_APP_ADMIN.reports.documents,
      element: <MaintenancePage />
    },
    // Reports section - INVOICES -
    {
      path: PATH_APP_ADMIN.reports.financial,
      element: <MaintenancePage />
    },

    // Analytics section - REDIRECT -
    {
      path: PATH_APP_ADMIN.analytics.root,
      element: <Navigate to={PATH_APP_ADMIN.analytics.financialFull} replace />
    },
    // Reports section - BUDGETS -
    {
      path: PATH_APP_ADMIN.analytics.procurement,
      element: <MaintenancePage />
    },
    // Reports section - PAYMENTS -
    {
      path: PATH_APP_ADMIN.analytics.documents,
      element: <MaintenancePage />
    },
    // Reports section - INVOICES -
    {
      path: PATH_APP_ADMIN.analytics.financial,
      element: <MaintenancePage />
    }
  ]
};

export default adminRoutes;
