/**
 * @description - The language changing view.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// React
import { useState, MouseEvent, useEffect } from 'react';
// @mui
import { MenuItem, Stack } from '@mui/material';
// hooks
import { useLocalStorage, useAuth } from 'src/hooks';
// components
import { Iconify, MenuPopover, IconButtonAnimate } from 'src/components';
// Types
import { TypeAccountProfile } from "src/@types";
// Stores
import { useDispatch, useSelector } from 'src/redux/store';
import { getCompaniesProfiles } from 'src/redux/slices';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export default function CompaniesSwitcherPopover() {
  const [selectedCompany, setSelectedCompany] = useLocalStorage<TypeAccountProfile | undefined>('company', undefined);

  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { companies } = useAuth();
  const dispatch = useDispatch();
  const { companiesProfiles, isCompaniesProfileLoading, companiesProfileFetchingError } =
    useSelector((state) => state.companiesProfiles);
  useEffect(() => {
    if (Array.isArray(companies) && companies.length > 0) {
      dispatch(getCompaniesProfiles(companies));
    }
  }, [companies]);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  if (companiesProfiles && companiesProfiles.length > 0) {
    return (
      <>
        <IconButtonAnimate
          onClick={handleOpen}
          sx={{
            width: 40,
            height: 40,
            fontSize: 6,
            ...(open && {bgcolor: 'action.selected'}),
          }}
        >
          <Iconify icon={'eva:briefcase-fill'} width={20} height={20} />
        </IconButtonAnimate>

        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {px: 1, typography: 'body2', borderRadius: 0.75},
          }}
        >
          <Stack spacing={0.75}>
            {companiesProfiles.map((company) => (
              <MenuItem
                key={company.uid}
                selected={company.uid === selectedCompany?.uid}
                onClick={() => {
                  setSelectedCompany(company);
                  handleClose();
                  // Refreshing the page
                  window.location.reload();
                }}
              >
                {company.display_name}
              </MenuItem>
            ))}
          </Stack>
        </MenuPopover>
      </>
    );
  }

  return null;
}
