/**
 * @description - The file contains all necessary models for the document
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

import { Timestamp, DocumentData } from "firebase/firestore";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

// ======================================================= DOC ====================================================== //
/**
 * @description - The variables for flow category
 */
export enum FlowCategoryEnum {
  'docs' = 'docs',
  'request_management' = 'request_management',
  'procurement' = 'procurement',
  'hr' = 'hr',
  'finance' = 'finance',
  'all' = 'all',
}
export type FlowCategory = keyof typeof FlowCategoryEnum;

/**
 * @description - The document  statuses objects
 */
export enum FlowStatusEnum {
  'active' = 'active',
  'draft' = 'draft',
  'suspended' = 'suspended',
  'archived' = 'archived',
  'removed' = 'removed',
  'all' = 'all',
}
export type FlowStatus = keyof typeof FlowStatusEnum;

/**
 * @description - The top level doc object model
 */
export type Flow = {
  // UIDs
  uid: string;
  company_uid: string;
  project_uid: string;
  created_by_uid: string;
  updated_by_uid: string | null;
  // STATUS
  active: boolean;
  status: FlowStatus;
  category: FlowCategory;
  // INFORMATION
  display_name: string;
  description: string;
  steps_number: number;
  // TIMESTAMP
  created: Timestamp;
  updated: Timestamp;
};

/**
 * @description - The method is converting the firestore db data into the doc object.
 * @param data - The firestore db data
 */
export function toFlow(data: DocumentData): Flow {
  return {
    uid: data.uid,
    company_uid: data.company_uid,
    project_uid: data.project_uid,
    created_by_uid: data.created_by_uid,
    updated_by_uid: data.updated_by_uid,
    active: data.active,
    status: data.status,
    category: data.category,
    display_name: data.display_name,
    description: data.description,
    steps_number: data.steps_number || 0,
    created: data.created,
    updated: data.updated,
  }
}

// ============================================== STEP OBJECT FROM FLOW= ============================================ //
/**
 * @description - The variables for the document types
 */
export enum StepActionEnum {
  'confirm' = 'confirm',
  'sign' = 'sign',
  'review' = 'review',
  'all' = 'all',
}
export type StepAction = keyof typeof StepActionEnum;

/**
 * @description - The document model
 */
export type Step = {
  // UIDs
  uid: string;
  company_uid: string;
  flow_uid: string;
  created_by_uid: string;
  updated_by_uid: string | null;
  // STATUS
  active: boolean;
  status: FlowStatus;
  order: number;
  // CATEGORIES
  action: StepAction;
  // INFORMATION
  display_name: string;
  description: string;
  // TIMESTAMP
  created: Timestamp;
  updated: Timestamp;
};

/**
 * @description - The method is converting the firestore db data into the document object.
 * @param data - The firestore db data
 */
export function toStep(data: DocumentData): Step {
  return {
    uid: data.uid,
    flow_uid: data.flow_uid || null,
    company_uid: data.company_uid,
    created_by_uid: data.created_by_uid,
    updated_by_uid: data.updated_by_uid,
    active: data.active,
    status: data.status,
    order: data.order,
    action: data.action,
    display_name: data.display_name,
    description: data.description,
    created: data.created,
    updated: data.updated,
  }
}
