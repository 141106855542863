/**
 * @description - The documents page related slices
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// Local types
import {
  TypeDocModel,
  TypeDocCategoryModel,
  TypeDocumentModel,
  TypeDocumentContentModel,
  TypeDocumentStatusModel,
  TypeDocumentTypeModel,
} from 'src/@types';
import { Doc, DocCategoryEnum } from 'src/services/documents/models';
// API
import {
  fetchAllDocsByCompanyUid,
  fetchAllDocumentsByDocUid,
  fetchDocsTotalNumberByCompanyUid,
  fetchDocByDocUid,
  fetchDocumentByDocumentUid,
  updateDocByDocUid, fetchAllDocsByProjectUid,
} from 'src/services/documents/documents';
import { ex } from "@fullcalendar/core/internal-common";

// ================================================================================================================== //
// ====================================================== STATE ===================================================== //
// ================================================================================================================== //

type DocsState = {
  isLoading: boolean;
  error: Error | string | null;
  success: string | null;
  info: string | null;
  totalDocs: number;
  docs: TypeDocModel[];
  doc: TypeDocModel | null;
  totalDocuments: number;
  documents: TypeDocumentModel[];
  document: TypeDocumentModel | null;
  contents: TypeDocumentContentModel[];
  requisites: TypeDocumentContentModel[];
};
const docsInitialState: DocsState = {
  isLoading: false,
  error: null,
  success: null,
  info: null,
  totalDocs: 0,
  docs: [],
  doc: null,
  totalDocuments: 0,
  documents: [],
  document: null,
  contents: [],
  requisites: [],
}

const docsSlice = createSlice({
  name: 'docs',
  initialState: docsInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS MESSAGE
    hasError(state, action) {
      state.isLoading = false;
      state.info = null;
      state.success = null;
      state.error = action.payload;
    },
    hasSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.info = null;
      state.success = action.payload;
    },
    hasInfo(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = null;
      state.info = action.payload;
    },
    resetMessages(state) {
      state.error = null;
      state.success = null;
      state.info = null;
    },
    // GET DOCS
    getTotalDocsSuccess(state, action) {
      state.isLoading = false;
      state.totalDocs = action.payload;
    },
    getDocsSuccess(state, action) {
      state.isLoading = false;
      state.docs = action.payload;
    },
    // GET DOC
    getDocSuccess(state, action) {
      state.isLoading = false;
      state.doc = action.payload;
    },
    // GET DOCUMENTS
    getTotalDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.totalDocuments = action.payload;
    },
    getDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.documents = action.payload;
    },
    // GET DOCUMENT
    getDocumentSuccess(state, action) {
      state.isLoading = false;
      state.document = action.payload;
    },
    // GET DOCUMENT'S CONTENTS
    getDocumentDataSuccess(state, action) {
      state.isLoading = false;
      state.contents = action.payload.contents;
      state.requisites = action.payload.requisites;
      state.document = action.payload.document;
    },
  }
});

export default docsSlice.reducer;
export const {} = docsSlice.actions;

// ================================================================================================================== //
// ==================================================== LOGIC | COMMON ============================================== //
// ================================================================================================================== //

/**
 * @description - The private method for the common fetch of the docs number
 * @param companyUid - The company uid
 * @param docCategory - The doc category type contract | document | template
 * @param active - the status of the document
 */
function getDocsTotalNumber(companyUid: string, docCategory: TypeDocCategoryModel, active?: boolean) {
  return () => {
    dispatch(docsSlice.actions.startLoading());
    try {
      fetchDocsTotalNumberByCompanyUid(
        companyUid,
        docCategory,
        (total) => dispatch(docsSlice.actions.getTotalDocsSuccess(total)),
        (error) => dispatch(docsSlice.actions.hasError(error)),
        active,
      );
    } catch (error) {
      dispatch(docsSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The common method to fetch docs by company uid
 * @param userUid
 * @param companyUid
 * @param docCategory
 * @param active
 * @param docsStatus
 * @param limitNumber
 * @param pageNumber
 * @param orderField
 * @param orderDirection
 */
function getDocsListByCompanyUid(
  userUid: string,
  companyUid: string,
  docCategory: TypeDocCategoryModel,
  active?: boolean,
  docsStatus?: TypeDocumentStatusModel,
  limitNumber?: number,
  pageNumber?: number, // ToDo add correct pagination
  orderField?: string,
  orderDirection?: "asc" | "desc",
) {
  return () => {
    dispatch(docsSlice.actions.startLoading());
    try {
      fetchAllDocsByCompanyUid(
        userUid,
        companyUid,
        docCategory,
        (docsList) => dispatch(docsSlice.actions.getDocsSuccess(docsList)),
        (error) => dispatch(docsSlice.actions.hasError(error)),
        docsStatus,
        limitNumber,
        undefined, // ToDo add correct pagination
        orderField,
        orderDirection,
        active,
      );
    } catch (error) {
      dispatch(docsSlice.actions.hasError(error));
    }
  }
}

export function getDocumentByDocumentUid(
  userUid: string,
  companyUid: string,
  docUid: string,
  documentUid: string,
) {
  return () => {
    dispatch(docsSlice.actions.startLoading());
    try {
      fetchDocumentByDocumentUid(
        companyUid,
        docUid,
        documentUid,
        (documentData) => dispatch(docsSlice.actions.getDocumentDataSuccess(documentData)),
        (error) => dispatch(docsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(docsSlice.actions.hasError(error));
    }
  }
}

// ================================================================================================================== //
// =================================================== LOGIC | CONTRACT ============================================= //
// ================================================================================================================== //

/**
 * @description - The method is fetching the total number of contracts for company
 * @param companyUid
 * @param active
 */
export function getTotalContracts(companyUid: string, active?: boolean) {
  return getDocsTotalNumber(companyUid, DocCategoryEnum.contract, active);
}

/**
 * @description - The method is fetching the contracts by company uid
 * @param userUid
 * @param companyUid
 */
export function getContractsByCompanyUid(
  userUid: string,
  companyUid: string,
) {
  return getDocsListByCompanyUid(userUid, companyUid, DocCategoryEnum.contract);
}

// ================================================================================================================== //
// =================================================== LOGIC | TEMPLATE ============================================= //
// ================================================================================================================== //

/**
 * @description - The method is fetching the total number of templates for company
 * @param companyUid
 * @param active
 */
export function getTotalTemplates(companyUid: string, active?: boolean) {
  return getDocsTotalNumber(companyUid, DocCategoryEnum.template, active);
}

/**
 * @description - The method is fetching the contracts by company uid
 * @param userUid
 * @param companyUid
 */
export function getTemplatesByCompanyUid(
  userUid: string,
  companyUid: string,
) {
  return getDocsListByCompanyUid(userUid, companyUid, DocCategoryEnum.template);
}

// ================================================================================================================== //
// =================================================== LOGIC | DOCUMENT ============================================= //
// ================================================================================================================== //

export function getTotalDocs(companyUid: string, active?: boolean) {
  return getDocsTotalNumber(companyUid, DocCategoryEnum.document, active);
}

/**
 * @description - The method is fetching the docs by company uid
 * @param userUid
 * @param companyUid
 */
export function getDocsByCompanyUid(
  userUid: string,
  companyUid: string,
) {
  return getDocsListByCompanyUid(userUid, companyUid, DocCategoryEnum.document);
}

// ================================================================================================================== //
// ===================================================== LOGIC | DOC ================================================ //
// ================================================================================================================== //

/**
 * @description - The method is fetching the docs by company uid
 * @param docUid - The doc uid
 */
export function getDocByUid(docUid: string) {
  return () => {
    dispatch(docsSlice.actions.startLoading());
    try {
      fetchDocByDocUid(
        docUid,
        (docData) => dispatch(docsSlice.actions.getDocSuccess(docData)),
        (error) => dispatch(docsSlice.actions.hasError(error)),
      );
    } catch (error) {
      console.log(error);
      dispatch(docsSlice.actions.hasError(error))
    }
  }
}

export function updateDocByUid(docUid: string, data: Partial<Doc>, onSuccess?: VoidFunction) {
  return () => {
    dispatch(docsSlice.actions.startLoading());
    try {
      updateDocByDocUid(
        docUid,
        data,
        // (docData) => dispatch(docsSlice.actions.getDocSuccess(docData)),
        onSuccess ? onSuccess : () => window.location.reload(),
        (error) => dispatch(docsSlice.actions.hasError(error)),
      );
    } catch (error) {
      console.log(error);
      dispatch(docsSlice.actions.hasError(error))
    }
  }
}

export function getDocumentsByDocUid(docUid: string) {
  return () => {
    dispatch(docsSlice.actions.startLoading());
    try {
      fetchAllDocumentsByDocUid(
        docUid,
        (documentsList) => dispatch(docsSlice.actions.getDocumentsSuccess(documentsList)),
        (error) => dispatch(docsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(docsSlice.actions.hasError(error));
    }
  }
}

export function getDocsByProjectUid(projectUid: string, onSuccess: (data: Record<string, any>[]) => void, onFail: (error: Error | string) => void) {
  try {
    fetchAllDocsByProjectUid(
      projectUid,
      onSuccess,
      onFail,
    );
  } catch (error) {
    onFail(error);
  }
}
