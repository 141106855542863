/**
 * @description - The all services that are related to the documents' data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import { Timestamp, addDoc } from 'firebase/firestore';
// Queries
import { querySubscriptionReference } from './queries';
// Local type
import { EmailSubscription } from './models';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is saving
 * @param subscriptionData
 * @param onSuccess
 * @param onFail
 */
export function createNewSubscriptionDocument(
  subscriptionData: Partial<EmailSubscription>,
  onSuccess: () => void,
  onFail: (error: string) => void
) {
  const subscriptionReference = querySubscriptionReference();
  addDoc(subscriptionReference, {
    ...subscriptionData,
    uid: subscriptionReference.id,
    created: Timestamp.now(),
    updated: Timestamp.now(),
  }).then(onSuccess).catch(onFail);
}
