import { ReactNode } from 'react';
import { useAuth, useLocalStorage } from '../../hooks';
import PagePermissionDenied from '../../pages/access/PagePermissionDenied';
import { LoadingScreen } from '../pages';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The wrapper component's property interface.
 */
type AdminRoleGuardProp = {
  children: ReactNode | string;
  path: string;
};

export default function AdminRoleGuard({ children, path }: AdminRoleGuardProp) {
  // Getting user based data
  const { employeeProfile, admin } = useAuth();
  // getting the company profile;
  const [selectedCompany, setSelectedCompany] = useLocalStorage('company', {});
  if (!admin || !employeeProfile) {
    return (<LoadingScreen />);
  }

  // Checking if the admin view defined and user does not have proper administrative permission
  if (!admin?.includes(selectedCompany?.user_uid)) {
    return (<PagePermissionDenied path={path} />);
  }

  return <>{children}</>;
}
