/**
 * @description - The date/time into proper format methods
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

import { format, getTime, formatDistanceToNow } from 'date-fns';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

export function fDate(date: Date | string | number | null | undefined) {
  // return format(new Date(date), 'dd MMMM yyyy');
  return date ? format(Date.now(), 'dd MMMM yyyy') : '';
}

export function fDateTime(date: Date | string | number | null | undefined) {
  return date ? format(new Date(date), 'dd MMM yyyy p') : '';
}

export function fTimestamp(date: Date | string | number | null | undefined) {
  return date ? getTime(new Date(date)) : '';
}

export function fDateTimeSuffix(date: Date | string | number | null | undefined) {
  return date ? format(new Date(date), 'dd/MM/yyyy hh:mm p') : '';
}

export function fToNow(date: Date | string | number | null | undefined) {
  return date ? formatDistanceToNow(new Date(date), {
    addSuffix: true
  }) : '';
}
