/**
 * @description - Interface that changing the title of the page. Can be passed to any upper level component. In this
 * project it is used mostly in Material UI's styled. Title changes are done through helmet module. Also passing the
 * user path into Google's analytic.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The interface for the Page component
 */
interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
}

/**
 * @description - The page view component
 */
const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | ${process.env.REACT_APP_PLATFORM_NAME}`}</title>
      {meta}
    </Helmet>

    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
));

export default Page;
