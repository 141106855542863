/**
 * @description - The all services that are related to the common data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import { getCountFromServer } from 'firebase/firestore';
// Queries
import { queryTotalNumberByCompanyUid, queryTotalNumberByCustomFields } from './queries';
// DB settings
import { collectionsPaths } from 'src/configs';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

type CollectionPathsKeyType = keyof typeof collectionsPaths;
type CollectionPathsType = typeof collectionsPaths[CollectionPathsKeyType];

/**
 * @description - The method is getting total number of the provided collection
 * @param company_uid - The company uid which data need to be provided
 * @param collection_path - The path which collection we need to fetch
 * @param onSuccess - The on success method
 * @param onFail - The on fail method
 * @param active
 */
export function fetchTotalNumberByCompanyUid(
  company_uid: string,
  collection_path: CollectionPathsType,
  onSuccess: (totalNumber: number) => void,
  onFail: (error: string) => void,
  active = true,
) {
  // Generating query
  const queryTotalNumber = queryTotalNumberByCompanyUid(company_uid, collection_path, active);
  if (queryTotalNumber) {
    getCountFromServer(queryTotalNumber)
      .then((snapshotTotalNumber) => onSuccess(snapshotTotalNumber.data().count))
      .catch((error) => onFail(error.message))
  } else {
    onFail('Something went wrong during fetching total number')
  }
}

/**
 * @description - The method is fetching total number of the server side
 * documents by custom fields
 * @param companyUid - The company uid for which the documents belong to
 * @param {[fieldName: string, fieldValue: string][]} customFields -
 *  The list of custom field name and value
 * @param collectionPath - The collection path
 * @param onSuccess
 * @param onFail
 * @param active
 */
export function fetchTotalNumberByCustomFields(
  companyUid: string,
  customFields: [string, string][],
  collectionPath: string,
  onSuccess: (totalNumber: number) => void,
  onFail: (error: string) => void,
  active = true,
) {
  const queryTotalNumber = queryTotalNumberByCustomFields(companyUid, customFields, collectionPath, active);
  if (queryTotalNumber) {
    getCountFromServer(queryTotalNumber)
      .then((snapshotTotalNumber) => onSuccess(snapshotTotalNumber.data().count))
      .catch((error) => onFail(error.message))
  } else {
    onFail('Something went wrong during fetching total number')
  }
}
