/**
 * @description - The highlighter module configuration which needed to do proper highlight during the code development
 * for different editors like Atom or Vim.
 */
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark-reasonable.css';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

declare global {
  interface Window {
    hljs: any;
  }
}

hljs.configure({
  languages: ['javascript', 'jsx', 'sh', 'bash', 'html', 'scss', 'css', 'json']
});

if (typeof window !== 'undefined') {
  window.hljs = hljs;
}
