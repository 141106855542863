/**
 * @description - The responsive hook that was changing the media size.
 */
// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Material UI
import { Breakpoint } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //
// Interfaces & Types
type Query = 'up' | 'down' | 'between' | 'only';
type Key = Breakpoint | number;
type Start = Breakpoint | number;
type End = Breakpoint | number;

/**
 * @description - The responsive hook that breaks the image based on property
 * @param query
 * @param key
 * @param start
 * @param end
 */
export default function useResponsive(query: Query, key?: Key, start?: Start, end?: End) {
  const theme = useTheme();
  let queryInput;
  switch (query) {
    case 'up':
      queryInput = theme.breakpoints.up(key as Key);
      break;
    case 'down':
      queryInput = theme.breakpoints.down(key as Key);
      break;
    case 'between':
      queryInput = theme.breakpoints.between(start as Start, end as End);
      break;
    case 'only':
      queryInput = theme.breakpoints.only(key as Breakpoint);
      break;
    default:
      queryInput = theme.breakpoints.only(key as Breakpoint);
      break;
  }
  return useMediaQuery(queryInput);
}
