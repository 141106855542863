import { PATH_APP_DOCS } from 'src/routes';
import { TypeNavConfig } from "src/@types";

// Icons
import {
  icon_elements,
  icon_file,
  icon_file_2,
  icon_archive,
  icon_reports1,
  icon_analytics,
  icon_mail,
  icon_folder,
} from 'src/components';

const navConfig: TypeNavConfig[] = [
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_APP_DOCS.dashboard.full,
        icon: icon_elements
      }
    ]
  },
  {
    subheader: 'files',
    items: [
      {
        title: 'contracts',
        path: PATH_APP_DOCS.active.contracts.full,
        icon: icon_file,
      },
      {
        title: 'documents',
        path: PATH_APP_DOCS.active.documents.full,
        icon: icon_file_2,
      },
      {
        title: 'templates',
        path: PATH_APP_DOCS.active.templates.full,
        icon: icon_folder,
      },
      {
        title: 'archive',
        path: PATH_APP_DOCS.archive.rootFull,
        icon: icon_archive,
        children: [
          {
            title: 'contracts',
            path: PATH_APP_DOCS.archive.contracts.full
          },
          {
            title: 'documents',
            path: PATH_APP_DOCS.archive.documents.full
          },
          {
            title: 'templates',
            path: PATH_APP_DOCS.archive.templates.full
          },
        ]
      },
    ]
  },
  {
    subheader: 'manage',
    items: [
      {
        title: 'reports',
        path: PATH_APP_DOCS.reports.full,
        icon: icon_reports1,
        children: [
          {
            title: 'contracts',
            path: PATH_APP_DOCS.reports.contracts.full
          },
          {
            title: 'documents',
            path: PATH_APP_DOCS.reports.documents.full
          },
        ]
      },
      {
        title: 'analytics',
        path: PATH_APP_DOCS.analytics.rootFull,
        icon: icon_analytics,
        children: [
          {
            title: 'contracts',
            path: PATH_APP_DOCS.analytics.contracts.full
          },
          {
            title: 'documents',
            path: PATH_APP_DOCS.analytics.documents.full
          },
        ]
      },
    ]
  },
  {
    subheader: 'communication',
    items: [
      {
        title: 'email',
        path: PATH_APP_DOCS.communication.full,
        icon: icon_mail,
      }
    ],
  },
]

export default navConfig;
