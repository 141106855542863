/**
 * @description - The responsive hook that was changing the media size.
 */
// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// React
import { useContext } from 'react';
import { SettingsContext } from '../contexts';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

// Initializing use Context method in order to use as a hook from top components
const useSettings = () => useContext(SettingsContext);
export default useSettings;
