/**
 * @description - The documents page related slices
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// API
import { createNewSubscriptionDocument } from 'src/services/subscriptions/subscriptions';

// ================================================================================================================== //
// ====================================================== STATE ===================================================== //
// ================================================================================================================== //

type SubscriptionState = {
  isLoading: boolean;
  error: Error | string | null;
  success: string | null;
};
const subscriptionsInitialState: SubscriptionState = {
  isLoading: false,
  error: null,
  success: null,
}

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: subscriptionsInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS MESSAGE
    hasError(state, action) {
      state.isLoading = false;
      state.success = null;
      state.error = action.payload;
    },
    hasSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload;
    },
  }
});

export default subscriptionsSlice.reducer;
export const {} = subscriptionsSlice.actions;

// ================================================================================================================== //
// ==================================================== LOGIC | COMMON ============================================== //
// ================================================================================================================== //

/**
 * @description - The method is creating new subscription
 * @param email
 * @param platform_name
 * @param description
 */
export function addNewEmailSubscription(
  email: string,
  platform_name: string,
  description?: string,
) {
  return () => {
    dispatch(subscriptionsSlice.actions.startLoading());
    try {
      createNewSubscriptionDocument(
        {
          display_name: email,
          description: description ?? null,
          platform_name,
        },
        () => dispatch(subscriptionsSlice.actions.hasSuccess(`Successfully subscribed with email - ${email}`)),
        (error) => dispatch(subscriptionsSlice.actions.hasError(error)),
      );
    } catch (error) {
      dispatch(subscriptionsSlice.actions.hasError(error));
    }
  }
}
