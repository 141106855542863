/**
 * @description - The all services that are related to the project's data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Common fetching data
import { fetchTotalNumberByCompanyUid } from '../common';
// Firestore
import { Timestamp, getDocs, getDoc, updateDoc, setDoc, addDoc } from 'firebase/firestore';
import {
  queryFetchGroupsByCompanyUid,
  getGroupReference,
  queryFetchGroupMembersByGroupUid,
  getGroupMemberReference,
  getGroupNewMemberReference,
} from "src/services/groups/queries";
import { Group, GroupMember, toGroup, toGroupMember } from './models';
import { groupsPaths } from 'src/configs';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is getting total number of the employees
 * @param company_uid
 * @param onSuccess
 * @param onFail
 */
export function fetchGroupsTotalNumberByCompanyUid(
  company_uid: string,
  onSuccess: (totalNumber: number) => void,
  onFail: (error: Error | string) => void,
) {
  fetchTotalNumberByCompanyUid(company_uid, groupsPaths.COLLECTION_GROUPS, onSuccess, onFail);
}

/**
 * @description - The method is fetching all groups by company uid
 * @param company_uid
 * @param onSuccess
 * @param onFail
 * @param orderByCategory
 * @param categoryOrderSort
 * @param limitNumber
 * @param pageNumber
 */
export function fetchAllGroupsByCompanyUid(
  company_uid: string,
  onSuccess: (allGroups: Group[]) => void,
  onFail: (error: Error | string) => void,
  orderByCategory?: string,
  categoryOrderSort?: 'asc' | 'desc',
  limitNumber?: number,
  pageNumber?: number,
) {
  const groupsQuery = queryFetchGroupsByCompanyUid(company_uid);
  if (groupsQuery) {
    // Trying to fetch the request forms
    getDocs(groupsQuery)
      .then((querySnapshot) => {
        const listOfGroups: Group[] = [];
        querySnapshot.forEach((groupSnapshot) => {
          const groupServerData = groupSnapshot.data();
          if (groupServerData) {
            const groupData = toGroup(groupServerData);
            if (groupData) {
              listOfGroups.push(groupData);
            }
          }
        });
        onSuccess(listOfGroups);
      })
      .catch((error) => onFail(error.message));
  } else {
    // If the user.uid is not defined or the user company is empty
    onFail('Cannot fetch groups');
  }
}

/**
 * @description - The method is fetching the group detailed info by group UID
 * @param group_uid
 * @param onSuccess
 * @param onFail
 */
export function fetchGroupByUid(
  group_uid: string,
  onSuccess: (group: Group) => void,
  onFail: (error: Error | string) => void,
) {
  const queryDetails = getGroupReference(group_uid);
  getDoc(queryDetails)
    .then((groupSnapshot) => {
      const groupServerData = groupSnapshot.data();
      if (groupServerData) {
        const groupData = toGroup(groupServerData);
        if (groupData) {
          onSuccess(groupData)
        } else {
          onFail(`Something went wrong, can't fetch group data ${group_uid}`);
        }
      }
    })
    .catch((error) => onFail(error.message))
}

/**
 * @description - The method is fetching the group members list by group UID
 * @param group_uid
 * @param onSuccess
 * @param onFail
 */
export function fetchGroupMembersByUid(
  group_uid: string,
  onSuccess: (groupMembers: GroupMember[]) => void,
  onFail: (error: Error | string) => void,
) {
  const queryMembers = queryFetchGroupMembersByGroupUid(group_uid);
  getDocs(queryMembers)
    .then((groupSnapshot) => {
      const listOfMembers: GroupMember[] = [];
      groupSnapshot.forEach((groupSnapshot) => {
        const groupMemberServerData = groupSnapshot.data();
        if (groupMemberServerData) {
          const member = toGroupMember(groupMemberServerData);
          if (member) {
            listOfMembers.push(member);
          }
        }
      });
      onSuccess(listOfMembers);
    })
    .catch((error) => onFail(error.message))
}

/**
 * @description - The method is updating member data of the group
 * @param group_uid
 * @param member_uid
 * @param data
 * @param onSuccess
 * @param onFail
 */
export function updateGroupMemberByUid(
  group_uid: string,
  member_uid: string,
  data: Partial<GroupMember>,
  onSuccess: () => void,
  onFail: (error: Error | string) => void,
) {
  const groupMemberReference = getGroupMemberReference(group_uid, member_uid);
  updateDoc(groupMemberReference, {
    ...data,
    updated: Timestamp.now(),
  }).then(onSuccess).catch(onFail);
}

/**
 * @description - The method is updating member data of the group
 * @param group_uid
 * @param data
 * @param onSuccess
 * @param onFail
 */
export function createGroupMemberByUid(
  group_uid: string,
  data: Partial<GroupMember>,
  onSuccess: () => void,
  onFail: (error: Error | string) => void,
) {
  const groupNewMemberReference = getGroupNewMemberReference(group_uid);
  // ToDo check how to add addDoc method instead of setDoc
  setDoc(groupNewMemberReference, {
    ...data,
    status: 'active',
    active: true,
    uid: groupNewMemberReference.id,
    created: Timestamp.now(),
    updated: Timestamp.now(),
  }).then(onSuccess).catch(onFail);
}
