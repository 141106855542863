/**
 * @description - The converter file that transforms server side data into internal object
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Document data from the firestore
import { DocumentData, Timestamp } from 'firebase/firestore';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export type AccountStatus = 'active' | 'suspended' | 'removed';
export type AccountType = 'person' | 'company';

/**
 * @description - User profile's type in the database
 */
export type AccountProfile = {
  // IDs and Statuses
  uid: string;
  user_uid: string | null;
  account_type: AccountType;
  status: AccountStatus,
  // Contacts
  email: string;
  phone_number: string | null;
  // Detailed info
  display_name: string;
  first_name: string;
  last_name: string;
  middle_name: string | null;
  photo_url: string | null;
  // Timestamp
  created: Timestamp;
  updated: Timestamp;
  // DB Management
  active?: boolean;
};

/**
 * @description - The permissions option that allowed to view/edit/manage appropriate platform
 */
export type Permission = {
  read: boolean;
  write: boolean;
}

/**
 * @description - User Employee profile's type in the database for the company based prrocessing
 */
export type EmployeeProfile = {
  // IDs and status
  uid: string;
  user_uid: string;
  company_uid: string;
  department_uid: string;
  status: AccountStatus;
  // Contacts
  email: string;
  phone_number: string | null;
  // Detailed info
  display_name: string;
  first_name: string;
  last_name: string;
  middle_name: string | null;
  photo_url: string | null;
  position: string;
  // Timestamp
  created: Timestamp;
  updated: Timestamp;
  // Permissions
  platforms: Record<string, Permission> | null;
  // DB Management
  active?: boolean;
  clt?: string[]
};

export type EmployeeProfileWithGroups = EmployeeProfile & { groups_uid: string[] };

/**
 * @description - validate the employee profile object
 * ToDo add the logic
 * @param profile
 */
function validateEmployeeProfile(profile: EmployeeProfile | AccountProfile): boolean {
  return true;
}

/**
 * @description - Converts the firestore db data into the employee profile object
 * @param documentData
 */
export function toEmployeeProfile(documentData: DocumentData): EmployeeProfile | null {
  const profileData: EmployeeProfile = {
    uid: documentData.uid,
    user_uid: documentData.user_uid,
    company_uid: documentData.company_uid,
    department_uid: documentData.department_uid,
    status: documentData.status,
    email: documentData.email,
    phone_number: documentData.phone_number || null,
    display_name: documentData.display_name,
    first_name: documentData.first_name,
    last_name: documentData.last_name,
    middle_name: documentData.middle_name || null,
    photo_url: documentData.photo_url || null,
    position: documentData.position,
    platforms: documentData.platforms || null,
    created: documentData.created,
    updated: documentData.updated,
    clt: documentData.clt ?? []
  }
  if (validateEmployeeProfile(profileData)) {
    return profileData;
  }
  return null;
}

/**
 * @description - The method is converting the document data into the account profile.
 * @param documentData
 */
export function toAccountProfile(documentData: DocumentData): AccountProfile | null {
  const accountProfile: AccountProfile = {
    uid: documentData.uid,
    user_uid: documentData.user_uid || documentData.uid || null,
    account_type: documentData.account_type,
    status: documentData.status,
    email: documentData.email || null,
    phone_number: documentData.phone_number || null,
    display_name: documentData.display_name,
    first_name: documentData.first_name,
    last_name: documentData.last_name,
    middle_name: documentData.middle_name || null,
    photo_url: documentData.photo_url || null,
    created: documentData.created,
    updated: documentData.updated,
  }
  if (validateEmployeeProfile(accountProfile)) {
    return accountProfile;
  }
  return null;
}
